import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Pacientes = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users')
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        setData(res);
      });
  }, []);

  return (
    <div className="container">
      <h2 className="my-5">Pacientes</h2>
      <div className="border-top">
        {data.map((item) => (
          <div className="d-flex border-bottom">
            <div className="d-flex flex-column my-3">
              <p className="m-0">{item.name}</p>
            </div>
            <div className="ml-auto align-self-center">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pacientes;
