import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

const ImageInput = ({ name, label, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const handlePreview = useCallback((e) => {
    const file = e.target.files?.[0];
    if (!file) {
      setPreview(null);
    } else {
      const previewURL = URL.createObjectURL(file);
      setPreview(previewURL);
    }
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_, value) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);
  return (
    <>
      {preview && (
        <img
          src={preview}
          alt="Preview"
          className="img-thumbnail rounded mx-auto d-block"
        />
      )}

      <div className="custom-file">
        <input
          type="file"
          ref={inputRef}
          id={name}
          onChange={handlePreview}
          className="custom-file-input"
          {...rest}
        />
        <label className="custom-file-label" htmlFor={name}>
          {label}
        </label>
      </div>
      {error && <span className="error">{error}</span>}
    </>
  );
};
export default ImageInput;
