import React, { useRef, useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
// import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import listPlugin from '@fullcalendar/list';

import ptLocale from '@fullcalendar/core/locales/pt-br';
import { faCog, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from 'react-bootstrap';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import Input from '../components/Input';
import CheckboxInput from '../components/CheckboxInput';
import InputMask from '../components/InputMask';
import { useAuth } from '../hooks/auth';
import api from '../utils/api';
import useIsMobile from '../hooks/useIsMobile';

const Agenda = () => {
  const { user } = useAuth();
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState([]);
  const ref = useRef(null);

  const [isMobile] = useIsMobile();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    ref.current.getApi().changeView(isMobile ? 'listMonth' : 'dayGridMonth');
  }, [isMobile]);

  useEffect(() => {
    if (!user) return;

    async function fetchData(userType = 2) {
      const type = userType == 2 ? 'psychologists' : 'patients';
      const {
        data: { data },
      } = await api.get(`users/${type}/schedules/all`);
      const newEvents = data.map((d) => {
        return {
          title: d.patient.name,
          start: `${d.date}T${d.start_at}`,
          end: `${d.date}T${d.end_at}`,
        };
      });
      setEvents(newEvents);
    }
    // console.log(user);

    fetchData(user.type);
  }, [user]);

  return (
    <div className="container my-4">
      <FullCalendar
        ref={ref}
        plugins={[dayGridPlugin, listPlugin]}
        initialView="dayGridMonth"
        events={events}
        locale={ptLocale}
        headerToolbar={{
          start: 'title',
          center: '',
          end:
            user?.type == 2
              ? 'myCustomButton today prev,next dayGridMonth,listMonth'
              : 'today prev,next dayGridMonth,listMonth',
        }}
        customButtons={{
          myCustomButton: {
            text: 'horarios!',
            click() {
              handleShow();
            },
          },
        }}
      />
      <ModalHorario show={show} handleClose={handleClose} />
    </div>
  );
};

export const ModalHorario = ({ show, handleClose }) => {
  const checkboxOptions = [
    { id: 'sunday', value: 'sunday', label: 'Dom' },
    { id: 'monday', value: 'monday', label: 'Seg' },
    { id: 'tuesday', value: 'tuesday', label: 'Ter' },
    { id: 'wednesday', value: 'wednesday', label: 'Qua' },
    { id: 'thursday', value: 'thursday', label: 'Qui' },
    { id: 'friday', value: 'friday', label: 'Sex' },
    { id: 'saturday', value: 'saturday', label: 'Sáb' },
  ];

  const mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
  const formRef = useRef(null);

  const { user, updateUser } = useAuth();
  const [hoursSchedule, setHoursSchedule] = useState([{}]);

  useEffect(() => {
    setHoursSchedule(user?.info?.availability);
  }, [user]);

  const addMore = () => setHoursSchedule((prev) => [...prev, {}]);
  const remove = (index) =>
    setHoursSchedule((prev) => prev.filter((_, i) => i !== index));

  async function handleSubmit(data) {
    const { days, availability } = data;
    const _data = {
      schedules: availability,
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        ...days,
      },
    };

    try {
      await api.put(`users/psychologists/${user.id}/availability`, _data);
      updateUser();
      handleClose();
    } catch (error) {
      console.error(error);
      alert('Algo deu errado!');
    }
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Configurar disponibilidade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={user?.info}>
          <>
            <label className="font-weight-bold">Horário disponível</label>
            {hoursSchedule?.map((schedule, i) => (
              <div className="row my-1">
                <Scope path={`availability[${i}]`}>
                  <Input name="id" className="d-none" />
                  <div className="col-5">
                    <InputMask
                      name="start_at"
                      placeholder="00:00"
                      mask={mask}
                    />
                  </div>
                  <span>ás</span>
                  <div className="col-5">
                    <InputMask name="end_at" placeholder="00:00" mask={mask} />
                  </div>
                  {i != 0 && (
                    <FontAwesomeIcon icon={faTrash} onClick={() => remove(i)} />
                  )}
                </Scope>
              </div>
            ))}
            <button
              className="btn btn-primary d-block mt-2"
              type="button"
              onClick={addMore}
            >
              Novo intervalo
            </button>
          </>
          <div className="my-3">
            <label className="font-weight-bold">Dias disponíveis</label>
            <div className="pt-3 border border-dark">
              <CheckboxInput name="days" options={checkboxOptions} />
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-outline-primary" onClick={handleClose}>
          Fechar
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={() => formRef.current.submitForm()}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Agenda;
