export default {
  mixed: {
    default: 'Campo inválido.',
    required: 'Campo obrigatório.',
  },
  string: {
    min: 'Use no mínimo ${min} caracteres.',
    email: 'E-mail inválido.',
    // matches: 'Telefone inválido.', // isso aqui é gambiarra
  },
  array: {
    min: 'Selecione no mínimo ${min}.',
  },
};
