import React from 'react';
import {
  Admin,
  BooleanField,
  FunctionField,
  Layout,
  ListGuesser,
  Resource,
} from 'react-admin';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import myDataProvider from '../myDataProvider';

import {
  companiesList,
  companiesCreate,
  companiesEdit,
} from '../components/companies';
import { pacientList, pacientEdit } from '../components/patient';
import { psychologistList, psychologistEdit } from '../components/psychologist';
import { scheduleList } from '../components/schedules';

const MyAppBar = (props) => <AppBar {...props} />;

const MyLayout = (props) => <Layout {...props} appBar={MyAppBar} />;
const AdminPannel = () => {
  return (
    <Admin layout={MyLayout} dataProvider={myDataProvider}>
      <Resource
        name="users/psychologists/all"
        options={{ label: 'Psicólogos' }}
        list={psychologistList}
        edit={psychologistEdit}
      />
      <Resource
        name="users/patients/all"
        options={{ label: 'Pacientes' }}
        list={pacientList}
        edit={pacientEdit}
      />
      <Resource
        name="users/companies"
        options={{ label: 'Empresas' }}
        list={companiesList}
        create={companiesCreate}
        edit={companiesEdit}
      />
      <Resource
        name="users/schedules/all"
        options={{ label: 'Agendamentos' }}
        list={scheduleList}
      />
      <Resource name="users/companies/active" />
    </Admin>
  );
};

export default AdminPannel;
