import React from 'react';

const Collapse = ({ title, items }) => (
  <div className="bg-gray-f1 rounded py-5">
    <h3 className="mx-5 mb-3 font-weight-bold">{title}</h3>
    <div className="accordion mx-5" id="accordion">
      {items.map((item, index) => (
        <div className="border-bottom b-">
          <div className="" id={`heading-${index}`}>
            <h2 className="mb-0 px-0">
              <button
                className="btn px-0 text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse-${index}`}
                aria-expanded="false"
                aria-controls={`collapse-${index}`}
              >
                {item.title}
              </button>
            </h2>
          </div>
          <div
            id={`collapse-${index}`}
            className="collapse"
            aria-labelledby={`heading-${index}`}
            data-parent="#accordion"
          >
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Collapse;
