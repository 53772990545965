/* eslint-disable no-confusing-arrow */
import {
  faPenSquare,
  faStar,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import img from '../assets/placeholder-img.png';
import { useAuth } from '../hooks/auth';
import InputMask from '../components/InputMask';
import Input from '../components/Input';
import { ModalHorario } from './Agenda';
import ImageInput from '../components/ImageInput';

import yupConfig from '../utils/yupConfig';
import api from '../utils/api';
import Select from '../components/Select';
import DateInput from '../components/DateInput';
import { ModalCadastrarBanco } from './Recebimentos';
import RadioInput from '../components/RadioInput';
// import ReactDatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

const MeuPerfil = () => {
  const { user } = useAuth();

  const photo = user?.photo_avatar
    ? `https://api.retouch.life/${user?.photo_avatar}`
    : img;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="container my-4 ">
      <Title />
      <div className="row">
        <div className="col-12 col-xl-2 imgEdit">
          <img src={photo} className="img-fluid photoSpecialist" alt="" />
          <div>
            <EditButton onClick={handleShow} />
          </div>
          <ModalFoto show={show} handleClose={handleClose} />
        </div>
        {user?.type == 2 && <SuperiorPsicologo />}
        {user?.type == 3 && <SuperiorPaciente />}
      </div>
      {/* <Superior handleShow={handleShow} /> */}
      <hr />
      <Contato />
      {user?.type == 2 && <Sobre />}
      {user?.type == 2 && <Formacao />}

      {user?.type != 1 && <Especialidades />}

      {/* {user?.type == 2 && <Avaliacoes />} */}
    </div>
  );
};

const Title = () => {
  const { user } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="d-flex ms-md-n5 pb-4">
      <h2 className="text-body font-weight-bold mb-0 ">
        {user?.name} <EditButton onClick={handleShow} />
      </h2>
      {user?.type == 2 && (
        <h3 className="ml-auto text-dark font-weight-bold mb-0">
          {user?.info?.stars}{' '}
          <FontAwesomeIcon icon={faStar} className="my-auto" />
        </h3>
      )}
      <ModalTitulo show={show} handleClose={handleClose} />
    </div>
  );
};

const ModalTitulo = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const { user, updateInfo, updateInfoPatient } = useAuth();

  const handleSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().min(4).required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (user.type == 2) {
        updateInfo(data);
      } else {
        updateInfoPatient(data);
      }
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={user}>
          <Input name="name" />
          {user?.info?.title && <Input name="info.title" />}
          {/* {agesOptions && <RadioInput name="age.id" options={agesOptions} />} */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => formRef.current.submitForm()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SuperiorPsicologo = () => {
  const { user, updateInfo, updateUser } = useAuth();

  const ref = useRef();
  const [editing, setEditing] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModalBanco, setShowModalBanco] = useState(false);

  const handleCloseModalBanco = () => setShowModalBanco(false);
  const handleShowModalBanco = () => setShowModalBanco(true);

  const [showModalValorConsulta, setShowModalValorConsulta] = useState(false);

  const handleCloseModalValorConsulta = () => setShowModalValorConsulta(false);
  const handleShowModalValorConsulta = () => setShowModalValorConsulta(true);

  const removeBank = () => {
    Swal.fire({
      title: 'Deseja remover sua conta?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Não`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await api.delete(`users/psychologists/${user.id}/bankaccount`);
        Swal.fire('Sucesso!', '', 'success');
        // window.location.reload();
        updateUser();
      }
    });
  };

  return (
    <>
      <div className="col-8 d-flex align-items-center">
        {editing ? (
          <textarea
            ref={ref}
            className="w-100"
            defaultValue={user?.info?.title}
          />
        ) : (
          <div className="d-flex flex-column">
            <h3 className="text-dark font-weight-bold ">{user?.info?.title}</h3>
            <button
              className="btn btn-sm btn-secondary my-1"
              type="button"
              onClick={handleShow}
            >
              Configurar disponibilidade
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary my-1"
              onClick={user?.bank_account ? removeBank : handleShowModalBanco}
            >
              {user?.bank_account ? 'Remover' : 'Cadastrar'} conta de banco
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary my-1"
              onClick={handleShowModalValorConsulta}
            >
              Alterar valor da consulta
            </button>
          </div>
        )}
      </div>
      <div className="col">{/* <EditButton onClick={onClick} /> */}</div>
      <ModalHorario show={show} handleClose={handleClose} />
      <ModalCadastrarBanco
        show={showModalBanco}
        handleClose={handleCloseModalBanco}
      />
      <ModalValorConsulta
        show={showModalValorConsulta}
        handleClose={handleCloseModalValorConsulta}
      />
    </>
  );
};

const ModalValorConsulta = ({ show, handleClose }) => {
  const { user, updateInfo } = useAuth();
  const formRef = useRef(null);

  async function handleSubmit(data) {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      const schema = Yup.object().shape({
        info: Yup.object().shape({
          schedule_value: Yup.string().required(),
        }),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed

      updateInfo(data);
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Form initialData={user} onSubmit={handleSubmit} ref={formRef}>
        <Modal.Body>
          <Input name="info.schedule_value" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Item = ({ label, value }) => (
  <>
    <small className="text-dark font-weight-bold">{label.toUpperCase()}</small>
    <p>{value}</p>
  </>
);

const SuperiorPaciente = () => {
  const { user, updateInfo } = useAuth();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="col-10 col-xl-8 d-flex align-items-center">
        <div>
          <Item label="idade" value={user?.age?.name} />
          <Item label="gênero" value={user?.gender?.name} />
        </div>
      </div>
      <div className="col">
        <EditButton onClick={handleShow} />
      </div>
      {/* <ModalHorario show={show} handleClose={handleClose} /> */}
      <ModalSuperiorPaciente show={show} handleClose={handleClose} />
    </>
  );
};

const ModalSuperiorPaciente = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const { user, updateInfo, updateInfoPatient } = useAuth();

  const [agesOptions, setAgesOptions] = useState([]);
  const [gendersOptions, setGendersOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const agesPromise = api.get('ages');
      const gendersPromise = api.get('genders');

      const transform = (item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
      });

      const [agesRes, gendersRes] = await Promise.all([
        agesPromise,
        gendersPromise,
      ]);

      const {
        data: { data: agesData },
      } = agesRes;
      const {
        data: { data: gendersData },
      } = gendersRes;

      const ages = agesData.map(transform);
      const genders = gendersData.map(transform);
      setAgesOptions(ages);
      setGendersOptions(genders);
    }

    fetchData();
  }, []);

  const handleSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        age: Yup.object().shape(),
        gender: Yup.object().shape(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      updateInfoPatient(data);

      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const indexValueAge = agesOptions.map((el) => el.id).indexOf(user.age.id);
  const indexValueGender = gendersOptions
    .map((el) => el.id)
    .indexOf(user.gender.id);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Idade</label>
            <Select
              name="age"
              options={agesOptions}
              defaultValue={agesOptions[indexValueAge]}
            />
          </div>
          <div className="form-group">
            <label>Gênero</label>
            <Select
              name="gender"
              options={gendersOptions}
              defaultValue={gendersOptions[indexValueGender]}
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => formRef.current.submitForm()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Contato = () => {
  const { user, updateInfo } = useAuth();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showContato, setShowContato] = useState(false);

  const handleCloseContato = () => setShowContato(false);
  const handleShowContato = () => setShowContato(true);

  return (
    <>
      <div className="row">
        <div className="col-10 col-xl-2">
          <h4 className="text-dark font-weight-bold">Contato</h4>
        </div>
        <div className="col-2 order-xl-2">
          <EditButton onClick={handleShowContato} />
        </div>
        <div className="col-8">
          <Item label="telefone" value={user?.phone} />
          <Item label="e-mail" value={user?.email} />
          <button
            className="btn btn-sm btn-outline-secondary my-1"
            type="button"
            onClick={handleShow}
          >
            Alterar senha
          </button>
        </div>
      </div>
      <hr />
      <ModalSenha show={show} handleClose={handleClose} />
      <ModalContato show={showContato} handleClose={handleCloseContato} />
    </>
  );
};

const ModalContato = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const { user, updateInfo, updateInfoPatient } = useAuth();

  const handleSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().required(),
        phone: Yup.string()
          .matches(
            /(\(\d{2}\)\s)(((?=9)\d{5}-\d{4})|((?=\d)\d{5}-\d{3})(?!\d))/,
            'Telefone inválido.'
          )
          .required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (user.type == 2) {
        updateInfo(data);
      } else {
        updateInfoPatient(data);
      }
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} initialData={user} onSubmit={handleSubmit}>
          <InputMask
            name="phone"
            placeholder="(00) 99999-9999"
            mask="(99) 99999-9999"
            label="Telefone"
          />
          <Input name="email" label="E-mail" />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => formRef.current.submitForm()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalSenha = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const { user, updateUser } = useAuth();

  const handleSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        password: Yup.string().min(8).required(),
        new_password: Yup.string().min(8).required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const url =
        user.type == 2
          ? `users/psychologists/${user.id}`
          : `users/patients/${user.id}`;

      await api.patch(url, data);
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        const message = 'Senha incorreta';
        formRef.current.setErrors({
          password: message,
        });
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Input name="password" label="Senha Atual" type="password" />
          <Input name="new_password" label="Nova Senha" type="password" />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => formRef.current.submitForm()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalFoto = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const { user, updateUser } = useAuth();

  const handleSubmit = async (data) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    Yup.setLocale({
      ...yupConfig,
    });

    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        photo_avatar: Yup.mixed()
          .test('fileSize', 'Arquivo muito pesado', (value) => {
            // if (!value.length) return true; // attachment is optional
            return value.size <= 200000;
          })
          .required(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append('photo_avatar', data.photo_avatar);

      const url =
        user.type == 2
          ? `users/psychologists/${user.id}/photo`
          : `users/patients/${user.id}/photo`;
      await api.post(url, formData, config);
      updateUser();
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enviar foto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ImageInput
            name="photo_avatar"
            label="Envie sua foto"
            accept="image/*"
            lang="pt-br"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
        <Button variant="primary" onClick={() => formRef.current.submitForm()}>
          Enviar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Sobre = () => {
  const { user, updateInfo } = useAuth();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row">
        <div className="col-10 col-xl-2">
          <h4 className="text-dark font-weight-bold">Sobre</h4>
        </div>

        <div className="col-2 order-xl-2">
          <EditButton onClick={handleShow} />
        </div>

        <div className="col-8">
          <p>{user?.info?.description}</p>
        </div>
      </div>
      <hr />
      <ModalSobre show={show} handleClose={handleClose} />
    </>
  );
};

const ModalSobre = ({ show, handleClose }) => {
  const { user, updateInfo } = useAuth();
  const formRef = useRef(null);

  async function handleSubmit(data) {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      const schema = Yup.object().shape({
        info: Yup.object().shape({
          description: Yup.string().min(10).required(),
        }),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed

      updateInfo(data);
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Form initialData={user} onSubmit={handleSubmit} ref={formRef}>
        <Modal.Body>
          <Input name="info.description" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const EditButton = ({ ...props }) => (
  <FontAwesomeIcon
    icon={faPenSquare}
    style={{ fontSize: 24, color: '#0087CB' }}
    className="c-pointer"
    {...props}
  />
);

const Formacao = () => {
  const { user, updateInfo } = useAuth();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row">
        <div className="col-10 col-xl-2">
          <h4 className="text-dark font-weight-bold my-4">Formação</h4>
        </div>

        <div className="col-2 order-xl-2">
          <EditButton onClick={handleShow} />
        </div>
        <div className="col-8">
          {user?.formations?.map((el, i) => (
            <div className="my-4" key={el.id.toString()}>
              <p className="font-weight-bold m-0">{el.name}</p>
              <p className="m-0">{el.area}</p>
              <p className="m-0">
                {dayjs(el.year_start).format('DD/MM/YYYY')}{' '}
                {dayjs(el.year_end).format('DD/MM/YYYY')}
              </p>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <FormFormacao show={show} handleClose={handleClose} />
    </>
  );
};

const FormFormacao = ({ show, handleClose }) => {
  const { user, updateInfo } = useAuth();
  const [formations, setFormations] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    setFormations(user.formations);
  }, [user.formations]);

  const addMore = () => setFormations((prev) => [...prev, {}]);
  const remove = (index) =>
    setFormations((prev) => prev.filter((_, i) => i !== index));

  async function handleSubmit(data) {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      const schema = Yup.object().shape({
        formations: Yup.array(
          Yup.object().shape({
            name: Yup.string().min(4).required(),
            area: Yup.string().required(),
            year_start: Yup.string().required(),
            year_end: Yup.string().required(),
          })
        )
          .min(1)
          .required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // console.log(data);
      updateInfo(data);
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const htmlFormations = formations.map((el, i) => (
    <Scope key={el?.id?.toString()} path={`formations[${i}]`}>
      <label>
        Formação {i + 1}{' '}
        {i != 0 && <FontAwesomeIcon icon={faTrash} onClick={() => remove(i)} />}
      </label>
      <Input name="id" placeholder="ID" className="d-none" />

      <div className="row row-cols-2">
        <div className="col">
          <Input name="name" label="Instituição de ensino" />
        </div>
        <div className="col">
          <Input name="area" label="Formação" />
        </div>
        <div className="col">
          <DateInput name="year_start" label="Ano de início" />
        </div>
        <div className="col">
          <DateInput name="year_end" label="Ano de término" />
        </div>
      </div>
    </Scope>
  ));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          initialData={{ formations }}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {htmlFormations}
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={addMore}
          >
            Adicionar
          </button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => formRef.current.submitForm()}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Especialidades = () => {
  const { user } = useAuth();

  const allFeatures = user?.features ? user?.features : user?.info?.features;

  const allSpecialties = user?.specialties
    ? user?.specialties
    : user?.info?.specialties;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="row">
        <div className="col-10 col-xl-2">
          <h4 className="text-dark font-weight-bold">
            {user?.type == 2 ? 'Especialidades' : 'Busco ajuda'}
          </h4>
        </div>

        <div className="col-2 order-xl-2">
          <EditButton onClick={handleShow} />
        </div>
        <div className="col-8">
          <div>
            {allSpecialties?.map((el) => (
              <p
                className="badge badge-secondary text-wrap m-1"
                color="#0087CB"
              >
                {el.name}
              </p>
            ))}
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-10 col-xl-2">
          <h4 className="text-dark font-weight-bold">Características</h4>
        </div>

        <div className="col-2 order-xl-2">
          <EditButton onClick={handleShow} />
        </div>
        <div className="col-8">
          <div>
            {allFeatures?.map((el) => (
              <p
                className="badge badge-secondary text-wrap m-1"
                color="#0087CB"
              >
                {el.name}
              </p>
            ))}
          </div>
        </div>
      </div>
      <hr />
      <ModalFeatures show={show} handleClose={handleClose} />
    </>
  );
};

const ModalFeatures = ({ show, handleClose }) => {
  const transform = (item) => ({
    id: item.id,
    value: item.id,
    label: item.name,
  });

  const { user, updateInfo, updateInfoPatient } = useAuth();
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const specialtiesPromise = api.get('specialties');
      const featuresPromise = api.get('features');

      const [specialtiesRes, featuresRes] = await Promise.all([
        specialtiesPromise,
        featuresPromise,
      ]);

      const {
        data: { data: specialtiesData },
      } = specialtiesRes;
      const {
        data: { data: featuresData },
      } = featuresRes;

      const specialties = specialtiesData.map(transform);
      const features = featuresData.map(transform);

      setSpecialtiesOptions(specialties);
      setFeaturesOptions(features);
    }

    fetchData();
  }, []);

  async function handleSubmit(data) {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      const schema = Yup.object().shape({
        features: Yup.array().min(1),
        specialties: Yup.array().min(1),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      if (user.type == 2) {
        updateInfo(data);
      } else {
        updateInfoPatient(data);
      }
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Form
        ref={formRef}
        initialData={{
          features: user?.features?.map(transform),
          specialties: user?.specialties?.map(transform),
        }}
        onSubmit={handleSubmit}
      >
        <Modal.Body>
          <div className="form-group">
            <label>{user?.type == 2 ? 'Especialidades' : 'Busco ajuda'}</label>
            <Select name="specialties" options={specialtiesOptions} isMulti />
          </div>
          <div className="form-group">
            <label>Características</label>
            <Select name="features" options={featuresOptions} isMulti />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const Avaliacoes = () => {
  const { user } = useAuth();
  const [avaliations, setAvaliations] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const {
        data: { data },
      } = await api.get(`users/psychologists/${user.id}/avaliations`);
      setAvaliations(data);
    }

    fetchData();
  }, []);
  const notas = [
    {
      txtnota:
        'Super profissional, completamente tranquilo nas suas colocações, bastante atencioso.',
    },
    {
      txtnota: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      txtnota: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  return (
    <div className="row">
      <div className="col-12 col-xl-2">
        <h4 className="text-dark font-weight-bold">Avaliações</h4>
      </div>

      <div className="col-8">
        {avaliations.length > 0 ? (
          notas.map((el) => (
            <div>
              <FontAwesomeIcon icon={faStar} color="#FDD835" />
              <FontAwesomeIcon icon={faStar} color="#FDD835" />
              <FontAwesomeIcon icon={faStar} color="#FDD835" />
              <FontAwesomeIcon icon={faStar} color="#FDD835" />
              <FontAwesomeIcon icon={faStar} color="#FDD835" />
              <p className="my-4">{el.txtnota}</p>
            </div>
          ))
        ) : (
          <p>Sem avaliações por enquanto ...</p>
        )}
      </div>
    </div>
  );
};
export default MeuPerfil;
