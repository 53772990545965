import React from 'react';
// import Button from '../Components/Button';

function Section1() {
  return (
    <section>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 my-3">
            <div className="justify-content-around">
              <h2>Tudo o que você precisa para sua consulta​ online</h2>
              <h4 className="my-4">
                É fácil encontrar o especialista certo, quando você tem a
                ferramenta certa.
              </h4>
              <a href="/seja-paciente" className="btn btn-primary">
                Agendar uma consulta
              </a>
              {/* <Button>Agendar uma consulta</Button> */}
            </div>
          </div>
          <div className="col-xl-6 my-3">
            <img src="photo_spotlight.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section1;
