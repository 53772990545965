import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../utils/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    // const id = localStorage.getItem('id');
    const user = localStorage.getItem('user');

    if (user) {
      setData({ user: JSON.parse(user) });
    }
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    // 1 - paciente
    // 2 - psicologo

    /* api
    1 - admin
    2 - psicologo
    3 - paciente
    */

    let id;
    if (email.includes('paciente')) {
      id = 1;
    } else id = 2;

    const {
      data: { access_token },
    } = await api.post('https://api.retouch.life/oauth/token', {
      grant_type: 'password',
      client_id: 2,
      client_secret: 's230lGUxXTP1NLNsiU8IEqz04xmilaCavT30sIu1',
      username: email,
      password,
      scope: '*',
    });
    api.defaults.headers.common.Authorization = `Bearer ${access_token}`;

    const {
      data: {
        data: [user],
      },
    } = await api.get('users/session');

    // const user = users.find((user) => user.email == email);

    setData({ user });
    localStorage.setItem('id', id);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', access_token);
  }, []);

  const signOut = useCallback(async () => {
    // api.post('users/logout');
    localStorage.removeItem('id');
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    setData({});
  }, []);

  const updateUser = useCallback(async () => {
    const {
      data: {
        data: [user],
      },
    } = await api.get('users/session');

    // const user = users.find((user) => user.email == email);

    setData({ user });
    localStorage.setItem('user', JSON.stringify(user));
  }, [setData, data.id]);

  const updateInfo = useCallback(async (updateData) => {
    // console.log(updateData);
    const user = JSON.parse(localStorage.getItem('user'));
    delete user.photo_avatar;
    const { info, features, specialties, companies } = user;
    const newInfo = { ...info, ...updateData.info };

    const mapFeatures = features.map((feat) => feat.id);
    const mapSpecialties = specialties.map((spec) => spec.id);
    const mapCompanies = companies.map((c) => c.id);

    // const newFeatures = { ...features, ...updateData.info }
    // const newFormations = { ...formations, ...updateData.formations };
    // const newData = { ...user, ...updateData };
    // console.log(newFormation);

    const newData = {
      ...user,
      features: mapFeatures,
      specialties: mapSpecialties,
      ...updateData,
      info: newInfo,
      companies: mapCompanies,
    };

    const {
      data: {
        data: [res],
      },
    } = await api.put(`users/psychologists/${user.id}`, newData);

    // console.log(res);

    setData((prev) => ({
      id: prev.id,
      user: res,
    }));

    localStorage.setItem('user', JSON.stringify(res));
  }, []);

  const updateInfoPatient = useCallback(async (updateData) => {
    const user = JSON.parse(localStorage.getItem('user'));
    delete user.photo_avatar;

    const { features, specialties, companies } = user;
    const mapFeatures = features.map((feat) => feat.id);
    const mapSpecialties = specialties.map((spec) => spec.id);
    const mapCompanies = companies.map((c) => c.id);

    const newData = {
      ...user,
      features: mapFeatures,
      specialties: mapSpecialties,
      companies: mapCompanies,
      ...updateData,
    };
    const {
      data: {
        data: [res],
      },
    } = await api.put(`users/patients/${user.id}`, newData);

    setData((prev) => ({
      id: prev.id,
      user: res,
    }));

    localStorage.setItem('user', JSON.stringify(res));
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        signIn,
        signOut,
        updateUser,
        updateInfo,
        updateInfoPatient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
