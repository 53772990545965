import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  DateField,
  Create,
  BooleanInput,
  maxValue,
  BooleanField,
  FunctionField,
  Filter,
  required,
  SearchInput,
} from 'react-admin';
import { StatusField } from '../Pages/Admin';

export const companiesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput
        source="status"
        valueLabelTrue="true"
        valueLabelFalse="false"
        initialValue
      />
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Create>
);

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const companiesList = (props) => (
  <List filters={<PostFilter />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        source="status"
        label="status"
        render={(record, source) => (
          <BooleanField
            record={{ ...record, status: !!record.status }}
            source={source}
          />
        )}
      />
      <TextField source="name" />
      <TextField source="code" />
      <DateField source="created_at" />
      <EditButton />
    </Datagrid>
  </List>
);

export const companiesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <BooleanInput source="status" valueLabelTrue="1" valueLabelFalse="0" />
      <TextInput source="name" />
      <TextInput source="code" />
    </SimpleForm>
  </Edit>
);
