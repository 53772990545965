import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function Section5() {
  return (
    <section>
      <div className="container">
        <div className="row text-center justify-content-center my-5 mx-5 px-5 ">
          <h2>Junte-se a centenas de pessoas que se consultam online</h2>
          <a href="/seja-paciente" className="btn btn-primary my-5">
            Agendar uma consulta <FontAwesomeIcon icon={faArrowRight} />{' '}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Section5;
