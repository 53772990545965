import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

export default function Input({ name, className, label, small, ...rest }) {
  const inputRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-group">
      {label && <label htmlFor={fieldName}>{label}</label>}
      {small && (
        <>
          {' '}
          <small id={fieldName} className="text-muted">
            {small}
          </small>
        </>
      )}

      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={`form-control${error ? ' is-invalid' : ''} ${className}`}
        onFocus={(e) => {
          if (e) clearError(e);
        }}
        {...rest}
      />

      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
}
