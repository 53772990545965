import dayjs from 'dayjs';
import React, { useMemo, useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid } from '@material-ui/data-grid';
import { Button, ButtonGroup } from '@material-ui/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import img from '../assets/placeholder-img.png';
import imgHomem from '../assets/placeholder-paciente-homem.jpg';
import { useAuth } from '../hooks/auth';
import api from '../utils/api';
import { ModalHorario } from './Agenda';

const Perfil = () => {
  const { user } = useAuth();
  const [schedules, setSchedules] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const photo = user?.photo_avatar
    ? `https://api.retouch.life/${user?.photo_avatar}`
    : img;

  const history = useHistory();

  useEffect(() => {
    if (user?.type == 1) window.location = '/admin';
  }, [user]);

  useEffect(() => {
    if (!user) return;

    async function fetchData(userType = 2) {
      const type = userType == 2 ? 'psychologists' : 'patients';
      const {
        data: { data: resData },
      } = await api.get(`users/${type}/schedules`);
      // console.log(resData);
      setSchedules(resData);
    }
    // console.log(user);

    fetchData(user.type);
  }, [user]);

  const cancelConsulta = (id) => {
    Swal.fire({
      title: 'Deseja cancelar?',
      showCancelButton: true,
      confirmButtonText: `Sim`,
      cancelButtonText: `Não`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await api.delete(`users/psychologists/schedule/${id}/cancel`);
        Swal.fire('Cancelado!', '', 'success');
        window.location.reload();
      }
    });
  };

  const schedulesCards = useMemo(
    () =>
      schedules.map((schedule) => {
        const { date, start_at, end_at } = schedule;
        const date1 = dayjs(`${date} ${start_at}`);
        const date2 = dayjs(`${date} ${end_at}`);
        const duracao = date2.diff(date1, 'hour');

        return (
          <div className="row">
            <div className="col-12 col-sm-6 card-paciente my-3 py-3 px-4">
              <small>{user?.type == 3 ? 'ESPECIALISTA' : 'PACIENTE'}</small>
              <p>
                {user?.type == 3
                  ? schedule.psychologist.name
                  : schedule.patient.name}
              </p>
              <hr />
              <small>DATA</small>
              <p>{dayjs(schedule.date).format('DD/MM/YYYY')}</p>
              <hr />
              <small>HORÁRIO</small>
              <p>{schedule.start_at}</p>
              <hr />
              <small>DURAÇÃO</small>
              <p>{duracao} HORA</p>
              <hr />
              <div className="d-flex">
                <a
                  className="btn btn-primary"
                  href={`/fale?uuid=${schedule.uuid}&id=${schedule.id}`}
                  role="button"
                >
                  Iniciar consulta
                </a>
                {user?.type == 3 && (
                  <>
                    {/* <button className="btn" type="button">
                      Reagendar
                    </button> */}
                    <button
                      className="btn text-primary"
                      type="button"
                      onClick={() => cancelConsulta(schedule.id)}
                    >
                      Cancelar
                    </button>
                  </>
                )}
              </div>
              {/* <button type="button" className="btn btn-primary">
            Iniciar consulta
          </button> */}
            </div>

            <div className="col">
              <img
                src={
                  schedule?.patient?.photo_avatar
                    ? `https://api.retouch.life/${schedule?.patient?.photo_avatar}`
                    : img
                }
                className="img-paciente"
                alt=""
              />
            </div>

            <div className="col">
              <img
                src={
                  schedule?.psychologist?.photo_avatar
                    ? `https://api.retouch.life/${schedule?.psychologist?.photo_avatar}`
                    : img
                }
                className="img-paciente"
                alt=""
              />
            </div>
          </div>
        );
      }),
    [schedules]
  );

  const handleOnClick = () => {
    if (user?.type == 2) {
      handleShow();
    } else history.push('/agendar-consulta');
  };

  return (
    <div className="container">
      <h2 className="my-4">
        {/* Boa tarde, {user.id == 1 ? 'Fernanda' : 'Eloá Marina Nina Fogaça!'} */}
        Olá, {user?.name}
      </h2>
      {schedulesCards}
      {user?.type != 1 && !schedulesCards?.length > 0 && (
        <div className="my-3 bg-gray-f1 p-3 rounded">
          <FontAwesomeIcon icon={faPlus} />
          <h3>
            Você ainda não tem consultas agendadas,{' '}
            {user?.type == 2
              ? 'clique abaixo para configurar os horários disponíveis.'
              : 'clique abaixo para agendar.'}
          </h3>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleOnClick}
          >
            {user?.type == 2 ? 'Configurar' : 'Agendar'}
          </button>
        </div>
      )}
      <ModalHorario show={show} handleClose={handleClose} />
    </div>
  );
};

export default Perfil;
