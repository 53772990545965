import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  DateField,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  maxValue,
  SelectInput,
  BooleanField,
  FunctionField,
  ReferenceInput,
  Filter,
  ReferenceField,
  SearchInput,
} from 'react-admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const pacientList = (props) => (
  <List filters={<PostFilter />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        source="status"
        label="status"
        render={(record, source) => (
          <BooleanField
            record={{ ...record, status: !!record.status }}
            source={source}
          />
        )}
      />
      <TextField source="name" />
      <ReferenceField source="companies" reference="users/companies">
        <TextField source="name" />
      </ReferenceField>
      <EmailField source="email" />
      <TextField source="phone" />
      <DateField source="created_at" />
      <EditButton />
    </Datagrid>
  </List>
);
export const pacientEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <BooleanInput source="status" valueLabelTrue="1" valueLabelFalse="0" />
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <ReferenceInput
        allowEmpty
        source="company"
        reference="users/companies/active"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
