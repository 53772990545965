import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import ReactDatePicker from 'react-datepicker';
import dayjs from 'dayjs';

export default function DateInput({ name, label, ...rest }) {
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    const oldValue = dayjs(defaultValue, 'YYYY-MM-DD').toDate();
    setStartDate(oldValue);
  }, [defaultValue]);

  const inputRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: (ref) => {
        if (!ref.props.selected) return '';
        return dayjs(ref.props.selected).format('YYYY-MM-DD');
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-group">
      {label && <label htmlFor={fieldName}>{label}</label>}

      <ReactDatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        dateFormat="dd/MM/yyyy"
        className="form-control"
        ref={inputRef}
        {...rest}
      />
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
}
