import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  DateField,
  BooleanInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FunctionField,
  BooleanField,
  Filter,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SearchInput,
} from 'react-admin';
import { StatusField } from '../Pages/Admin';

const PostFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const psychologistList = (props) => (
  <List filters={<PostFilter />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        source="status"
        label="status"
        render={(record, source) => (
          <BooleanField
            record={{ ...record, status: !!record.status }}
            source={source}
          />
        )}
      />
      <TextField source="name" />
      <ReferenceArrayField source="companies" reference="users/companies">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EmailField source="email" />
      <TextField source="info.crp" />
      <TextField source="phone" />
      <DateField source="created_at" />
      <EditButton />
    </Datagrid>
  </List>
);
export const psychologistEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <BooleanInput source="status" valueLabelTrue="1" valueLabelFalse="0" />
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput disabled source="info.crp" label="CRP" />
      <TextInput source="phone" />
      <ReferenceArrayInput
        source="companies"
        reference="users/companies/active"
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
