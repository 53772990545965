import { useField } from '@unform/core';
import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-input-mask';

const InputMask = ({ name, className, label, small, ...rest }) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue(value);
      },
      clearValue(ref) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={fieldName}>
          {label}
          {small && (
            <>
              {' '}
              <small id={fieldName} className="text-muted">
                {small}
              </small>
            </>
          )}
        </label>
      )}

      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        className={`form-control${error ? ' is-invalid' : ''} ${
          className || ''
        }`}
        onFocus={(e) => {
          if (e) clearError(e);
        }}
        maskPlaceholder={null}
        {...rest}
      />

      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};

export default InputMask;
