import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import img from '../assets/placeholder-img.png';
import api from '../utils/api';
import { useAuth } from '../hooks/auth';

// const data = [
//   {
//     nome: 'Eloá Marina Nina Fogaça',
//     star: '4,44',
//     price: 90,
//   },
//   {
//     nome: 'Eloá Marina Nina Fogaça',
//     star: '4,44',
//     price: 90,
//   },
//   {
//     nome: 'Eloá Marina Nina Fogaça',
//     star: '4,44',
//     price: 90,
//   },
//   {
//     nome: 'Eloá Marina Nina Fogaça',
//     star: '4,44',
//     price: 90,
//   },
//   {
//     nome: 'Eloá Marina Nina Fogaça',
//     star: '4,44',
//     price: 90,
//   },
//   {
//     nome: 'Eloá Marina Nina Fogaça',
//     star: '4,44',
//     price: 90,
//   },
// ];

const AgendarConsulta = () => {
  const history = useHistory();
  const [psychologists, setPsychologists] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    async function fetchData() {
      const {
        data: { data },
      } = await api.get('users/psychologists');
      // console.log(data);
      setPsychologists(data);
    }
    fetchData();
  }, []);

  return (
    <div className="container my-4">
      <h3>{user?.name}, veja o que recomendamos</h3>
      <h4 className="text-body">Recomendados para você</h4>
      <div className="row">
        {psychologists?.map((el) => (
          <Card info={el} />
        ))}
      </div>
      {/* <Recomendamos /> */}
      {/* <Populares /> */}
    </div>
  );
};

const Card = ({ info }) => {
  const photo = info?.photo_avatar
    ? `https://api.retouch.life/${info?.photo_avatar}`
    : img;

  return (
    <a className="col-6 col-sm-2" href={`/psicologo/${info.id}`}>
      <div>
        <img src={photo} className="img-fluid rounded photoSpecialist" alt="" />

        <p
          className="overflow-hidden mb-0 text-body"
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
        >
          {info.name}
        </p>
        <div className="d-flex align-items-center">
          <p className="text-body">
            {info.info.stars}
            <FontAwesomeIcon icon={faStar} />
          </p>
          {/* <p className=" ml-auto text-primary font-weight-bold">
              {info.price.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p> */}
        </div>
      </div>
    </a>
  );
};

// const Recomendamos = () => {
//   return (
//     <>
//       <h4 className="text-body">Recomendados para você</h4>
//       <div className="row">
//         {data?.map((el) => (
//           <Card info={el} />
//         ))}
//       </div>
//     </>
//   );
// };

// const Populares = () => {
//   return (
//     <>
//       <h4 className="text-body">Mais populares</h4>
//       <div className="row">
//         {data?.map((el) => (
//           <Card info={el} />
//         ))}
//       </div>
//     </>
//   );
// };

export default AgendarConsulta;
