import React, { useEffect } from 'react';

const useBackgroundColor = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none';
    document.body.style.backgroundColor = '#F1F2F6';
    document.querySelector('header').style = 'display: none';
    document.querySelector('footer').style = 'display: none';

    return () => {
      document.body.style.backgroundColor = 'white';
      document.querySelector('header').style = 'display: block';
      document.querySelector('footer').style = 'display: block';
    };
  }, []);
};

export default useBackgroundColor;
