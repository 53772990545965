import React from 'react';

import Section1 from '../Sections/Section1';
import Section2 from '../Sections/Section2';
import Section4 from '../Sections/Section4';
import Section5 from '../Sections/Section5';

function Home() {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section4 />
      <Section5 />
    </>
  );
}

export default Home;
