import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import Footer from './Sections/Footer';
import Header from './Sections/Header';

import Home from './Pages/Home';
import Central from './Pages/Central';

import Login from './Pages/Login';
import Perfil from './Pages/Perfil';
import Fale from './Pages/Fale';
import EsqueciSenha from './Pages/EsqueciSenha';
import SejaParceiro from './Pages/SejaParceiro';
import Recebimentos from './Pages/Recebimentos';
import { AuthProvider, useAuth } from './hooks/auth';
import Pacientes from './Pages/Pacientes';
import MeuPerfil from './Pages/MeuPerfil';
import Agenda from './Pages/Agenda';
import AgendarConsulta from './Pages/AgendarConsulta';
import SejaPaciente from './Pages/SejaPaciente';
import Psicologo from './Pages/Psicologo';
import AdminPannel from './Pages/Admin';
import api from './utils/api';
// import logo from './logo.svg';
// import './App.css';

function App() {
  useEffect(() => {
    if (localStorage.getItem('token')) {
      api.get('users/session').catch((err) => {
        api.post('users/logout');
        localStorage.removeItem('id');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location = '/login';
      });
    }
  }, []);
  return (
    <>
      <AuthProvider>
        <Router>
          <Header />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/central" component={Central} />
            <Route exact path="/login" component={Login} />
            <Route path="/perfil" component={Perfil} />
            <Route path="/fale" component={Fale} />
            <Route path="/esqueci-senha" component={EsqueciSenha} />
            <Route path="/seja-parceiro" component={SejaParceiro} />
            <Route path="/seja-paciente" component={SejaPaciente} />
            <PrivateRoute path="/recebimentos" component={Recebimentos} />
            <PrivateRoute path="/pacientes" component={Pacientes} />
            <PrivateRoute path="/meu-perfil" component={MeuPerfil} />
            <PrivateRoute path="/agenda" component={Agenda} />
            <PrivateRoute
              path="/agendar-consulta"
              component={AgendarConsulta}
            />
            <PrivateRoute path="/psicologo/:id" component={Psicologo} />
            <PrivateRoute path="/admin" component={AdminPannel} />
          </Switch>

          <Footer />
        </Router>
      </AuthProvider>
    </>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  // let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('token') ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
