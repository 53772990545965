import React from 'react';

import logo from '../assets/logotipoFooter.png';

function Footer() {
  return (
    <footer>
      <div className="container border-top py-5">
        <div className="row text-center">
          <div className="col-12 col-xl-4 d-flex align-items-center justify-content-center">
            <a href="/#" className="mx-3">
              &copy; 2020 retouch.life
            </a>
            <a href="/central">Central de ajuda</a>
          </div>
          <div className="col-12 col-xl-4 py-3 py-xl-0">
            <img
              src="/logotipo.svg"
              alt=""
              loading="lazy"
              className="m-auto"
              height="70px"
            />
          </div>
          <div className="col-12 col-xl-4 d-flex align-items-center justify-content-center">
            <a href="/#" className="mx-3">
              Política de Privacidade
            </a>
            <a href="/#">Termos de uso</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
