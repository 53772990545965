/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-confusing-arrow */
import { faPenSquare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import { Button, Modal, Spinner } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { Router, useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Axios from 'axios';
import api from '../utils/api';
import 'react-datepicker/dist/react-datepicker.css';
import Input from '../components/Input';
import InputMask from '../components/InputMask';
import { useAuth } from '../hooks/auth';
import img from '../assets/placeholder-img.png';
import formatPrice from '../utils/formatPrice';
// import ReactDatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

const Psicologo = ({ match }) => {
  const { user } = useAuth();
  const [psychologist, setPsychologist] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const {
        data: {
          data: [psychologist],
        },
      } = await api.get(`users/psychologists/${id}/profile`);
      // console.log(psychologist);
      setPsychologist(psychologist);
    }

    fetchData();
  }, []);

  return (
    <div className="container my-4">
      <Superior psychologist={psychologist} />
      <hr />
      <Sobre psychologist={psychologist} />
      <hr />
      <Formacao psychologist={psychologist} />
      <hr />
      <Especialidade psychologist={psychologist} />
      <hr />
      {/* <Avaliacoes /> */}
    </div>
  );
};

const Superior = ({ psychologist }) => {
  const { user, updateInfo } = useAuth();

  const ref = useRef();
  const [editing, setEditing] = useState(false);

  const onClick = async () => {
    setEditing(!editing);
    if (editing) {
      try {
        const { value } = ref.current;
        await updateInfo({ info: { title: value } });
      } catch (error) {
        alert('Algo deu errado!');
        console.error(error);
      }
    }
  };
  const photo = psychologist?.photo_avatar
    ? `https://api.retouch.life/${psychologist?.photo_avatar}`
    : img;

  return (
    <>
      <div className="d-flex pb-4">
        <h2 className="text-body font-weight-bold mb-0 ">
          {psychologist?.name}
        </h2>
        <h3 className="ml-auto text-dark font-weight-bold mb-0">
          {psychologist?.info?.stars}
        </h3>
        <FontAwesomeIcon icon={faStar} className="my-auto" />
      </div>
      <div className="row">
        <div className="col-12 col-xl-2 imgEdit">
          <img src={photo} className="img-fluid photoSpecialist" alt="" />
        </div>

        <div className="col-8 d-flex align-items-center">
          <h3 className="text-dark font-weight-bold ">
            {psychologist?.info?.title}
          </h3>
        </div>
      </div>
    </>
  );
};

const Sobre = ({ psychologist }) => {
  const { user, updateInfo } = useAuth();

  const formRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [avaliableDays, setAvaliableDays] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();

  useEffect(() => {
    if (!psychologist.id) return;
    setSelectedTag('');
    async function fetchData() {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await api.get(
          `users/psychologists/${psychologist.id}/availability/${dayjs(
            startDate
          ).format('YYYY-MM-DD')}`
        );

        setTags(data);
      } catch (error) {
        console.error(
          'Falha ao pegar os horarios disponiveis do psicologo',
          error
        );
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [startDate]);

  const agendarVisita = () => {
    const _data = {
      start_at: selectedTag,
      date: dayjs(startDate).format('YYYY-MM-DD'),
      patient_id: user?.id,
    };
    api
      .post(`users/psychologists/${psychologist.id}/schedule`, _data)
      .then(() => {
        handleClose();
        history.push('/perfil');
      });
  };

  function openCheckout() {
    const value = psychologist?.info?.schedule_value.replace(/\W/g, '');
    const recipient_id = psychologist?.bank_account?.recipient_id;

    const checkout = new window.PagarMeCheckout.Checkout({
      encryption_key: 'ek_test_4xkWDao7SfHMh8pbeWxlGtwGnZF1ut',
      success(data) {
        const postData = {
          api_key: 'ak_test_Yu6TybYEx3gUT4VZmix0ot7s3Rxw7l',
          amount: value,
          split_rules: [
            {
              recipient_id: 're_ckjbi2z4105tu0g9tydm87q7k',
              percentage: 20,
              liable: true,
              charge_processing_fee: true,
            },
            {
              recipient_id,
              percentage: 80,
              liable: false,
              charge_processing_fee: false,
            },
          ],
        };

        Axios.post(
          `https://api.pagar.me/1/transactions/${data.token}/capture`,
          postData
        );
        agendarVisita();
      },
      error(err) {
        console.error(err);
      },
    });

    checkout.open({
      amount: value,
      paymentMethods: 'credit_card',
      customerData: 'true',
      createToken: 'true',
      items: [
        {
          id: '1',
          title: 'retouch.life Consulta',
          unit_price: value,
          quantity: 1,
          tangible: 'false',
        },
      ],
    });
  }

  const isWeekday = (date) => {
    const day = date.getDay();
    // const day = date;
    // console.log(day);
    return day !== 0 && day !== 6;
  };

  const strAgendamento =
    !user?.companies?.length > 0
      ? `Agendar por ${formatPrice(psychologist?.info?.schedule_value || 0)}`
      : 'Agendar';

  return (
    <div className="row">
      <div className="col-8 col-xl-2">
        <h4 className="text-dark font-weight-bold">Sobre</h4>
      </div>

      <div className="col-2 order-xl-2">
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          {strAgendamento}
        </button>
      </div>

      <div className="col-8">
        <p>{psychologist?.info?.description}</p>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione uma data e horário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <ReactDatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              inline
              locale="pt-BR"
              useWeekdaysShort
              minDate={new Date()}
              filterDate={isWeekday}
            />
          </div>
          <div className="text-center">
            {!isLoading && tags.length == 0 && (
              <p>Sem horarios disponivel para esse dia, tente outro dia</p>
            )}
            {!isLoading &&
              tags.map((tag) => (
                <span
                  className={`badge ${
                    selectedTag == tag.start_at
                      ? 'badge-primary'
                      : 'badge-secondary'
                  } mx-2 my-1 py-2 c-pointer`}
                  onClick={() => setSelectedTag(tag.start_at)}
                >
                  {tag.start_at} - {tag.end_at}
                </span>
              ))}
            {isLoading && <Spinner animation="border" variant="primary" />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          {!user?.companies?.length ? (
            <Button
              variant="primary"
              onClick={() => openCheckout()}
              disabled={startDate && !selectedTag}
            >
              Pagar e Agendar
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => agendarVisita()}
              disabled={startDate && !selectedTag}
            >
              Agendar
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const EditButton = ({ ...props }) => (
  <FontAwesomeIcon
    icon={faPenSquare}
    style={{ fontSize: 24, color: '#0087CB' }}
    className="c-pointer"
    {...props}
  />
);

const Formacao = ({ psychologist }) => {
  const data = [
    {
      universidade: 'Universidade de São Paulo',
      sobre: 'Doutorando, Psicologia Comportamental',
      data: '2018 - 2020',
    },

    {
      universidade: 'Universidade de São Paulo',
      sobre: 'Mestrado, Psicologia Comportamental',
      data: '2014 - 2016',
    },

    {
      universidade: 'Universidade de São Paulo',
      sobre: 'Graduação, Psicologia Comportamental',
      data: '2009 - 2013',
    },
  ];

  const { user, updateInfo } = useAuth();

  const ref = useRef();
  const [editing, setEditing] = useState(false);

  const onClick = async () => {
    setEditing(!editing);
    if (editing) {
      ref.current.submitForm();
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      await updateInfo(data);
    } catch (error) {
      alert('Algo deu errado!');
      console.error(error);
    }
  };

  return (
    <div className="row">
      <div className="col-10 col-xl-2">
        <h4 className="text-dark font-weight-bold my-4">Formação</h4>
      </div>

      <div className="col-8">
        {psychologist?.formations?.map((el, i) => (
          <div className="my-4" key={el.id.toString()}>
            <p className="font-weight-bold m-0">{el.name}</p>
            <p className="m-0">{el.area}</p>
            <p className="m-0">
              {dayjs(el.year_start).format('DD/MM/YYYY')}{' '}
              {dayjs(el.year_end).format('DD/MM/YYYY')}
            </p>
          </div>
        ))}
      </div>
      {/* <div className="col">
        <EditButton onClick={onClick} />
      </div> */}
    </div>
  );
};

const Especialidade = ({ psychologist }) => {
  const { specialties = [], features = [] } = psychologist;

  const esp = [
    {
      txtnom: 'Ansiedade',
    },
    {
      txtnom: 'Conflitos amorosos',
    },
    {
      txtnom: 'Depressão',
    },
    {
      txtnom: 'Relacionamento',
    },
    {
      txtnom: 'Transtorno de personalidade',
    },
  ];

  const { user } = useAuth();

  return (
    <div className="row">
      <div className="col-10 col-xl-2">
        <h4 className="text-dark font-weight-bold">Especialidade</h4>
      </div>

      <div className="col-8">
        <div>
          {specialties?.concat(features).map((el) => (
            <p
              key={el.id}
              className="badge badge-secondary text-wrap m-1"
              color="#0087CB"
            >
              {el.name}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

const Avaliacoes = () => {
  const notas = [
    {
      txtnota:
        'Super profissional, completamente tranquilo nas suas colocações, bastante atencioso.',
    },
    {
      txtnota: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      txtnota: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  return (
    <div className="row">
      <div className="col-2">
        <h4 className="text-dark font-weight-bold">Avaliacoes</h4>
      </div>

      <div className="col-8">
        {notas.map((el) => (
          <div>
            <FontAwesomeIcon icon={faStar} color="#FDD835" />
            <FontAwesomeIcon icon={faStar} color="#FDD835" />
            <FontAwesomeIcon icon={faStar} color="#FDD835" />
            <FontAwesomeIcon icon={faStar} color="#FDD835" />
            <FontAwesomeIcon icon={faStar} color="#FDD835" />
            <p className="my-4">{el.txtnota}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Psicologo;
