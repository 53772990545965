/* eslint-disable import/prefer-default-export */
import React from 'react';

export const Card = ({
  img,
  imgStyle,
  description,
  title,
  paragraph,
  children,
}) => (
  <div className="card my-3 my-xl-0">
    <div className="card-body text-center">
      <img src={img} className="img-fluid my-4" style={imgStyle} alt="" />
      {description && <p className="card-hint ">{description}</p>}
      <h3 className="card-title px-3">{title}</h3>
      {paragraph && <p className="card-text px-2 mb-3">{paragraph}</p>}
      {children}
    </div>
  </div>
);
