/* eslint-disable react/button-has-type */
import React, { useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Input from '../components/Input';
import yupConfig from '../utils/yupConfig';
import api from '../utils/api';

// TODO: CHANGE THIS TO UNFORM

const EsqueciSenha = () => {
  const formRef = useRef(null);

  const handleSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('users/recovery/password', data);
      Swal.fire('Enviado', 'Verifique seu Email', 'success');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-6 my-3">
          <h3>Esqueceu sua senha?</h3>
          <h4>
            Complete abaixo e nós enviaremos um e-mail com os próximos passos.
          </h4>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="email" label="E-mail" />
          </Form>
          <button
            type="button"
            className="btn btn-lg btn-primary btn-block my-3"
            onClick={() => formRef.current.submitForm()}
          >
            Enviar e-mail
          </button>
        </div>
      </div>
    </div>
  );
};

export default EsqueciSenha;
