/* eslint-disable import/prefer-default-export */
import {
  faCheck,
  faMoneyBill,
  faStar,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  DateField,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  maxValue,
  SelectInput,
  BooleanField,
  FunctionField,
  ReferenceInput,
  ReferenceField,
} from 'react-admin';

export const scheduleList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />

      <ReferenceField source="user_patient_id" reference="users/patients/all">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="user_psychologist_id"
        reference="users/psychologists/all"
      >
        <TextField source="name" />
      </ReferenceField>

      <DateField source="date" />
      <TextField source="start_at" />
      <TextField source="end_at" />

      <FunctionField
        source="canceled"
        label={<FontAwesomeIcon icon={faTimes} />}
        render={(record, source) => (
          <BooleanField
            record={{ ...record, canceled: !!record.canceled }}
            source={source}
          />
        )}
      />

      <FunctionField
        source="paid"
        label={<FontAwesomeIcon icon={faMoneyBill} />}
        render={(record, source) => (
          <BooleanField
            record={{ ...record, paid: !!record.paid }}
            source={source}
          />
        )}
      />

      <FunctionField
        source="concluded"
        label={<FontAwesomeIcon icon={faCheck} />}
        render={(record, source) => (
          <BooleanField
            record={{ ...record, concluded: !!record.concluded }}
            source={source}
          />
        )}
      />

      <FunctionField
        source="avaliation"
        label={<FontAwesomeIcon icon={faStar} />}
        colum
        render={(record, source) => (
          <BooleanField
            record={{ ...record, avaliation: !!record.avaliation }}
            source={source}
          />
        )}
      />

      <DateField source="created_at" />

      {/* <TextField source="value" /> */}
      <TextField source="comment" />
      {/* <EditButton /> */}
    </Datagrid>
  </List>
);
