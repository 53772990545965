import { Form } from '@unform/web';
import React, { useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Input from '../components/Input';
import { useAuth } from '../hooks/auth';
import yupConfig from '../utils/yupConfig';

const Login = () => {
  const { signIn } = useAuth();

  const formRef = useRef(null);

  const history = useHistory();

  const onSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().min(8).required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      await signIn(data);
      history.push('/perfil');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        const message = 'Login Incorreto';
        formRef.current.setErrors({
          email: message,
          password: message,
        });
      }
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col col-sm-6 my-3">
          <h3>Bem-vindo(a), novamente!</h3>
          <h4>Preencha abaixo para acessar sua conta.</h4>
          <Form onSubmit={onSubmit} ref={formRef}>
            <Input name="email" label="E-mail" />
            <Input name="password" label="Senha" type="password" />
            <button
              className="btn btn-lg btn-primary btn-block my-3"
              type="submit"
            >
              Acessar conta
            </button>
            <a
              role="button"
              className="btn btn-link d-flex mx-auto text center my-3 justify-content-center"
              href="/esqueci-senha"
            >
              Esqueci minha senha
            </a>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
