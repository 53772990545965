// import simpleRestProvider from 'ra-data-simple-rest';

import { useAuth } from './hooks/auth';
import api from './utils/api';

const myDataProvider = {
  //   ...dataProvider,
  getList: (resource, params) => {
    // console.log(params.filter.q);
    const regex = new RegExp(`${params?.filter?.q}`, 'gi');

    return api.get(resource).then((res) => {
      let { data } = res.data;
      if (params?.filter?.q)
        data = data.filter(({ name }) => name.match(regex));
      if (/psychologists|patients/.test(resource)) {
        const newData = data.map((d) => {
          d.companies = d.companies.map((c) => c.id);
          return d;
        });
        return {
          data: newData,
          total: res.data.data.length,
        };
      }
      return {
        data,
        total: res.data.data.length,
      };
    });
  },
  getOne: (resource, params) => {
    let newStr;

    if (/companies/.test(resource)) {
      newStr = `${resource}/${params.id}`;
    } else newStr = `${resource.replace('all', params.id)}/profile`;

    return api.get(newStr).then((res) => {
      const data = res.data.data[0];
      if (/psychologists|patients/.test(resource)) {
        const newCompanies = data.companies.map((c) => c.id);
        return {
          data: { ...data, companies: newCompanies, company: newCompanies[0] },
        };
      }
      return { data };
    });
  },
  create: (resource, params) => {
    return api.post(resource, params.data).then((res) => {
      return { data: res.data.data[0] };
    });
  },
  update: (resource, params) => {
    const url = /companies/.test(resource)
      ? `${resource}/${params.id}`
      : `${resource.replace('all', params.id)}`;
    if (/psychologists|patients/.test(resource)) {
      const newPsy = params.data;

      delete newPsy.photo_avatar;
      newPsy.specialties = newPsy.specialties.map((s) => s.id);
      newPsy.features = newPsy.features.map((f) => f.id);
      // newPsy.companies = newPsy.companies.map((c) => c.id);

      return api.put(url, newPsy).then((res) => {
        return { data: res.data.data[0] };
      });
    }
    return api.put(url, params.data).then((res) => {
      return { data: res.data.data[0] };
    });
    // return api.put(`users/psychologists/${user.id}`, newData);
  },
  getMany: (resource, params) => {
    return api.get(resource).then((res) => {
      const { data } = res.data;
      return { data };
    });
  },
};

export default myDataProvider;
