import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

const RadioInput = ({ name, options, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error, defaultValue = '' } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => refs.find((ref) => ref.checked)?.value || '',
      setValue: (refs, id) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <>
      {options.map((option) => (
        <div
          className={`custom-control custom-radio ${error ? 'is-invalid' : ''}`}
          key={option.id}
        >
          <input
            ref={(ref) => inputRefs.current.push(ref)}
            id={option.id}
            className="custom-control-input"
            type="radio"
            name={name}
            defaultChecked={defaultValue.includes(option.id)}
            value={option.value}
            {...rest}
          />
          <label htmlFor={option.id} className="custom-control-label">
            {option.label}
          </label>
        </div>
      ))}
      {error && <span className="invalid-feedback">{error}</span>}
    </>
  );
};
export default RadioInput;
