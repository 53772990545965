import React from 'react';

import pc from '../assets/ic-pc.png';
import phone from '../assets/ic-phone.png';
import { Card } from '../Pages/Card';

function Section4() {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <h3>Não importa onde você está, estaremos sempre ao seu alcance</h3>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-xl mx-1">
            <Card
              img={pc}
              imgStyle={{ height: 160 }}
              title="Em seu computador"
              paragraph="retouch.life pode ser executado no seu computador, o que é ótimo se você tiver um dedicado para isso."
            >
              <a className="btn btn-outline-secondary" href="/seja-paciente">
                Começar agora
              </a>
            </Card>
          </div>
          <div className="col-xl mx-1">
            <Card
              img={phone}
              imgStyle={{ height: 160 }}
              title="Em seu dispositivo móvel"
              paragraph="Obtenha o aplicativo para iOS ou Android e execute retouch.life de seu telefone ou tablet."
            >
              <button type="button" className="btn btn-outline-secondary mx-1">
                App Store
              </button>
              <button type="button" className="btn btn-outline-secondary mx-1">
                Google Play
              </button>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
