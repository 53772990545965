import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Scope } from '@unform/core';
import { Form } from '@unform/web';
import { Typeahead } from 'react-bootstrap-typeahead';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import Input from '../components/Input';
import InputMask from '../components/InputMask';
import { useAuth } from '../hooks/auth';
import api from '../utils/api';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import useBackgroundColor from '../utils/useBackgroundColor';
import DateInput from '../components/DateInput';
import yupConfig from '../utils/yupConfig';
import Select from '../components/Select';
import Textarea from '../components/Textarea';

Yup.addMethod(Yup.string, 'isNewEmail', function (args) {
  return Yup.string()
    .email()
    .test(`isNewEmail`, async function test(value) {
      const { path, createError } = this;
      if (!value) return;

      const {
        data: [resEmail],
      } = await api.post('user/emailcheck', {
        email: value,
      });

      return resEmail != 'false' || createError({ path, message: args });
    });
});

Yup.addMethod(Yup.string, 'isValidCrp', function (args) {
  return Yup.string().test(`isValidCrp`, async function test(value) {
    const { path, createError } = this;
    if (!value) return;
    const {
      data: [resCrp],
    } = await api.post(`psychologists/crpcheck/${value}`);

    return resCrp != 'false' || createError({ path, message: args });
  });
});

const SejaParceiro = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const formRef = useRef(null);
  useBackgroundColor();

  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    status: true,
  });
  const [info, setInfo] = useState({
    about: 'about of psycho',
    google_id: 'id######@#$!!@#',
    stars: 5,
    schedule_value: 120.0,
  });

  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const specialtiesPromise = api.get('specialties');
      const featuresPromise = api.get('features');

      const transform = (item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
      });

      const [specialtiesRes, featuresRes] = await Promise.all([
        specialtiesPromise,
        featuresPromise,
      ]);

      const {
        data: { data: specialtiesData },
      } = specialtiesRes;
      const {
        data: { data: featuresData },
      } = featuresRes;

      const specialties = specialtiesData.map(transform);
      const features = featuresData.map(transform);

      setSpecialtiesOptions(specialties);
      setFeaturesOptions(features);
    }

    fetchData();
  }, []);

  async function handleSubmit(data) {
    Yup.setLocale({
      ...yupConfig,
    });
    // console.log(data);
    let schema;
    formRef.current.setErrors({});
    try {
      switch (step) {
        case 1:
          schema = Yup.object().shape({
            email: Yup.string().isNewEmail('E-mail já cadastrado.').required(),
            password: Yup.string().min(8).required(),
            phone: Yup.string()
              .matches(
                /(\(\d{2}\)\s)(((?=9)\d{5}-\d{4})|((?=\d)\d{5}-\d{3})(?!\d))/,
                'Telefone inválido.'
              )
              .required(),
            info: Yup.object().shape({
              crp: Yup.string()
                .isValidCrp('CRP já cadastrado.')
                .min(7)
                .required(),
            }),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 3:
          schema = Yup.object().shape({
            name: Yup.string().min(4).required(),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 4:
          schema = Yup.object().shape({
            info: Yup.object().shape({
              title: Yup.string().required(),
            }),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 5:
          schema = Yup.object().shape({
            info: Yup.object().shape({
              description: Yup.string().min(10).required(),
            }),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 6:
          schema = Yup.object().shape({
            formations: Yup.array(
              Yup.object().shape({
                name: Yup.string().min(4).required(),
                area: Yup.string().required(),
                year_start: Yup.string().required(),
                year_end: Yup.string().required(),
              })
            )
              .min(1)
              .required(),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 7:
          schema = Yup.object().shape({
            specialties: Yup.array().min(1),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 8:
          schema = Yup.object().shape({
            features: Yup.array().min(1),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;

        default:
          break;
      }

      const newInfo = {
        ...info,
        ...data.info,
      };
      setInfo(newInfo);
      const newForm = { ...form, ...data, info: { ...newInfo } };
      setForm(newForm);

      if (step != 8) {
        setStep(step + 1);
      } else {
        setStep(step + 1);
        try {
          await api.post('users/psychologists', newForm);

          await signIn({
            email: form.email,
            password: form.password,
          });

          history.push('/meu-perfil');
        } catch (error) {
          console.error(error);
          alert('Algo deu errado, tente novamente');
        }
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        // console.log(validationErrors);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const handleGoBack = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const props = {
    step,
    formRef,
    form,
    nextStep,
  };

  const goBack = step != 1 && (
    <>
      <button
        type="button"
        className="btn btn-outline-primary my-3 mx-5"
        onClick={handleGoBack}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
    </>
  );

  const CurrentStep = () => {
    switch (step) {
      case 1:
        return <Step1 {...props} />;
      case 2:
        return <Step2 {...props} />;
      case 3:
        return <Step3 {...props} />;
      case 4:
        return <Step4 {...props} />;
      case 5:
        return <Step5 {...props} />;
      case 6:
        return <Step6 {...props} />;
      case 7:
        return <Step7 {...props} resOptions={specialtiesOptions} />;
      case 8:
        return <Step8 {...props} resOptions={featuresOptions} />;
      default:
        return <Spinner animation="border" variant="primary" />;
    }
  };

  return (
    <>
      <div className="container">
        {goBack}
        <Form
          ref={formRef}
          className="row justify-content-center"
          onSubmit={handleSubmit}
          initialData={form}
        >
          <CurrentStep />
        </Form>
      </div>
      {step > 2 && step < 9 && (
        <div className="container-fluid bg-white py-5">
          <div className="row justify-content-center">
            <div className="col-5 my-3">
              {[
                'Qual é o seu nome?',
                'Crie um título para seu perfil.',
                'Faça um resumo sobre você.',
                'Adicione sua formação acadêmica.',
                'Quais são as suas especialidades?',
                'Quais as características que você se identifica?',
              ].map((stringStep, i) => {
                if (i + 2 >= step) {
                  return <h5>{stringStep}</h5>;
                }
                return null;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Step1 = () => {
  function beforeMaskedStateChange({ nextState }) {
    let { value } = nextState;
    if (value.endsWith('/')) {
      value = value.slice(0, -1);
    }

    return {
      ...nextState,
      value,
    };
  }

  return (
    <div className="col-12 col-sm-6 my-3">
      <h3>Bem-vindo(a)!</h3>
      <h4>Conecte-se com pacientes criando seu perfil em poucos minutos.</h4>

      <Input
        name="email"
        type="email"
        placeholder="exemplo@email.com.br"
        label="E-mail"
      />
      <Input name="password" type="password" label="Senha" />
      <InputMask
        name="phone"
        placeholder="(00) 99999-9999"
        mask="(99) 99999-9999"
        label="Telefone"
      />
      <InputMask
        name="info.crp"
        placeholder="4539965"
        label="CRP"
        mask="9999999"
      />

      <button type="submit" className="btn btn-lg btn-primary btn-block my-3">
        Aceitar e cadastrar
      </button>
      <a
        href="/login"
        className="text-primary d-flex justify-content-center mb-3"
      >
        Se você ja tem uma conta, clique aqui para entrar
      </a>
      <small className="d-flex flex-column text-center justify-content-center">
        <p>Ao cadastrar seus dados, você concorda com nossos</p>
        <div>
          <span className="text-primary">Termos e Condições</span> e{' '}
          <span className="text-primary">Política de Privacidade.</span>
        </div>
      </small>
    </div>
  );
};

const Step2 = ({ step, nextStep }) => {
  if (step !== 2) {
    return null;
  }
  return (
    <div className="col-10 my-3">
      <h2>
        Muito bem!
        <br /> Para criar seu perfil, vou precisar saber algumas informações
        sobre você.
      </h2>
      <button className="btn btn-primary my-3" type="button" onClick={nextStep}>
        Sim, continuar
      </button>
    </div>
  );
};

const Step3 = ({ step }) => {
  if (step !== 3) {
    return null;
  }
  const isValid = true;

  return (
    <div className="col-8 my-3">
      <CustomInput
        name="name"
        label="Qual é o seu nome?"
        placeholder="Digite aqui sua resposta"
      />
      <button
        className={
          isValid ? 'btn btn-primary my-3' : 'btn btn-outline-dark my-3'
        }
        disabled={!isValid}
        type="submit"
      >
        Próxima pergunta
      </button>
    </div>
  );
};

const Step4 = ({ step }) => {
  if (step !== 4) {
    return null;
  }
  const isValid = true;

  return (
    <div className="col-8 my-3">
      <CustomInput
        name="info.title"
        label="Crie um título para seu perfil."
        placeholder="Digite aqui sua resposta"
      />
      <button
        className={
          isValid ? 'btn btn-primary my-3' : 'btn btn-outline-dark my-3'
        }
        disabled={!isValid}
        type="submit"
      >
        Próxima pergunta
      </button>
    </div>
  );
};

const Step5 = ({ step }) => {
  if (step !== 5) {
    return null;
  }
  const isValid = true;

  return (
    <div className="col-8 my-3">
      <CustomTextarea
        name="info.description"
        label="Faça um resumo sobre você."
        placeholder="Digite aqui sua resposta"
      />
      <button
        className={
          isValid ? 'btn btn-primary my-3' : 'btn btn-outline-dark my-3'
        }
        disabled={!isValid}
        type="submit"
      >
        Próxima pergunta
      </button>
    </div>
  );
};

const Step6 = ({ step, formRef, form }) => {
  const isValid = true;

  const [formations, setFormations] = useState([{}]);

  useEffect(() => {
    if (!form.formations) return;
    setFormations(form.formations);
  }, [form.formations]);

  const addMore = () => setFormations((prev) => [...prev, {}]);
  const remove = (index) =>
    setFormations((prev) => prev.filter((_, i) => i !== index));

  return (
    <div className="col-8 my-3">
      {formations.length == 0 && (
        <p>É necessario ao menos uma formação academica</p>
      )}
      {formations.map((formation, i) => (
        <>
          <div className="d-flex">
            <h5>Adicione sua formação acadêmica.</h5>
            {i != 0 && (
              <FontAwesomeIcon icon={faTrash} onClick={() => remove(i)} />
            )}
          </div>
          <Scope path={`formations[${i}]`}>
            <CustomInput name="name" placeholder="Instituição de ensino" />
            <CustomInput name="area" placeholder="Formação" />
            <div className="form-group">
              <CustomDate
                name="year_start"
                dateFormat="dd/MM/yyyy"
                placeholderText="Ano de início"
              />
            </div>

            <div className="form-group">
              <CustomDate
                name="year_end"
                dateFormat="dd/MM/yyyy"
                placeholderText="Ano de término (ou previsto)"
              />
            </div>
          </Scope>
        </>
      ))}

      <div className="d-flex">
        <button
          className="btn btn-outline-dark my-3 mr-1"
          onClick={addMore}
          type="button"
        >
          Adicionar mais uma
        </button>

        <button
          className={
            isValid
              ? 'btn btn-primary my-3 ml-1'
              : 'btn btn-outline-dark my-3 ml-1'
          }
          disabled={!isValid}
          type="submit"
        >
          Próxima pergunta
        </button>
      </div>
    </div>
  );
};

const Step7 = ({ resOptions }) => {
  const isValid = true;

  const [options, setOptions] = useState(resOptions || []);

  return (
    <div className="col-8 my-3">
      <h5>Quais são as suas especialidades?</h5>
      <Select name="specialties" options={options} isMulti />
      <button
        className={
          isValid ? 'btn btn-primary my-3' : 'btn btn-outline-dark my-3'
        }
        disabled={!isValid}
        type="submit"
      >
        Próxima pergunta
      </button>
    </div>
  );
};

const Step8 = ({ resOptions }) => {
  const [options, setOptions] = useState(resOptions || []);

  return (
    <div className="col-8 my-3">
      <h5>Quais as características que você se identifica?</h5>
      <Select name="features" options={options} isMulti />
      <button className="btn btn-primary my-3" type="submit">
        Finalizar
      </button>
    </div>
  );
};

const CustomInput = ({ label, ...props }) => (
  <div className="form-group">
    {label && <h5>{label}</h5>}
    <Input
      className="px-0 form-control-lg border-bottom border-dark border-top-0 border-left-0 border-right-0 rounded-0"
      style={{ fontSize: '2.25rem', background: '#F1F2F6' }}
      {...props}
    />
  </div>
);

const CustomTextarea = ({ label, ...props }) => (
  <div className="form-group">
    {label && <h5>{label}</h5>}
    <Textarea
      className="px-0 form-control form-control-lg border-bottom border-dark border-top-0 border-left-0 border-right-0 rounded-0"
      style={{ fontSize: '2.25rem', background: '#F1F2F6' }}
      {...props}
    />
  </div>
);

const CustomDate = ({ label, ...props }) => (
  <div>
    {label && <h5>{label}</h5>}

    <DateInput
      className="px-0 form-control custom form-control-lg border-bottom border-dark border-top-0 border-left-0 border-right-0 rounded-0"
      {...props}
    />
  </div>
);

export default SejaParceiro;
