/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Input from '../components/Input';
import Textarea from '../components/Textarea';

import camera from '../assets/placeholder-camera.png';

import yupConfig from '../utils/yupConfig';
import api from '../utils/api';
import { useAuth } from '../hooks/auth';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Fale = () => {
  const query = useQuery();
  const [finished, setFinished] = useState(false);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const formRef = useRef(null);
  const history = useHistory();
  const { user } = useAuth();

  const onMouseEnter = (index) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };
  const onSaveRating = (index) => {
    setRating(index);
  };

  const handleSubmit = async (data) => {
    Yup.setLocale({
      ...yupConfig,
    });
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        comment: Yup.string().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      const url =
        user.type == 2
          ? `users/psychologists/schedule/${query.get('id')}/concluded`
          : `users/patients/schedule/${query.get('id')}/concluded`;

      await api.patch(url, {
        avaliation: rating,
        ...data,
      });
      history.push('/perfil');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const initialData =
    user?.type == 2 ? { comment: '' } : { comment: 'sem comentario' };

  return (
    <div className="container">
      {!finished ? (
        <div className="row my-5">
          <div className="col-12 col-xl-10">
            {/* <img src={camera} className="img-fluid my-4" alt="" /> */}
            <iframe
              title="meet"
              allow="camera; microphone; display-capture"
              src={`https://meet.jit.si/${query.get('uuid')}`}
              allowFullScreen="true"
              style={{ height: '600px', width: '100%' }}
            />
          </div>
          <div className="col text-center align-self-center">
            {/* <h3>Está tudo certo para iniciar?</h3> */}
            <button
              className="btn btn-primary"
              onClick={() => setFinished(true)}
              type="button"
            >
              Finalizar Consulta
              {/* <FontAwesomeIcon icon={faVideo} /> */}
            </button>
          </div>
        </div>
      ) : (
        <div className="my-5 text-center">
          <h2>A consulta vai ser encerrada</h2>
          <h3>Tem certeza?</h3>
          <div className="card mx-5">
            <div className="card-body">
              <span>Avaliação</span>
              <div className="d-flex justify-content-center">
                {[1, 2, 3, 4, 5].map((index) => {
                  return (
                    <RatingIcon
                      index={index}
                      rating={rating}
                      hoverRating={hoverRating}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onSaveRating={onSaveRating}
                    />
                  );
                })}
              </div>

              <Form
                className="mt-3"
                ref={formRef}
                initialData={initialData}
                onSubmit={handleSubmit}
              >
                <div className={`${(user?.type == 3 && 'd-none') || ''}`}>
                  <Textarea
                    name="comment"
                    label="Anotações da consulta"
                    className="form-control"
                  />
                </div>
              </Form>
            </div>
          </div>

          <div className="mt-5">
            <button
              type="button"
              className="btn btn-outline-secondary mr-3"
              onClick={() => setFinished(false)}
            >
              Voltar
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => formRef.current.submitForm()}
              disabled={rating == 0}
            >
              Finalizar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

function RatingIcon(props) {
  const {
    index,
    rating,
    hoverRating,
    onMouseEnter,
    onMouseLeave,
    onSaveRating,
  } = props;
  const fill = React.useMemo(() => {
    if (hoverRating >= index) {
      return 'yellow';
    }
    if (!hoverRating && rating >= index) {
      return 'yellow';
    }
    return 'none';
  }, [rating, hoverRating, index]);
  return (
    <div
      className="cursor-pointer"
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}
    >
      <StarIcon fill={fill} />
    </div>
  );
}

function StarIcon(props) {
  const { fill = 'none' } = props;
  return (
    <svg
      className="w-6 h-6"
      fill={fill}
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
      />
    </svg>
  );
}

export default Fale;
