/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import Axios from 'axios';
import dayjs from 'dayjs';
import Input from '../components/Input';
import InputMask from '../components/InputMask';
import Select from '../components/Select';
import yupConfig from '../utils/yupConfig';
import api from '../utils/api';
import { useAuth } from '../hooks/auth';
import Loading from './Loading';
import { DataGrid } from '@material-ui/data-grid';

const Recebimentos = () => {
  const [show, setShow] = useState(false); // TODO: CHANGE ME

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { user } = useAuth();
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    async function fetchData() {
      setIsLoading(true);
      const type = user?.type == 2 ? 'psychologists' : 'patients';

      try {
        const {
          data: { data },
        } = await api.get(`users/${type}/schedules/finalized`);

        setSchedules(
          data.map((item) => {
            const name =
              user?.type == 2 ? item.patient.name : item.psychologist.name;
            const { id, date, comment, value } = item;
            return {
              id,
              date: dayjs(date).format('DD/MM/YYYY'),
              comment,
              value,
              name,
            };
          })
        );
      } catch (error) {
        console.error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [user]);

  const columns = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Nome', flex: 1 },
    {
      field: 'date',
      headerName: 'Data',
      width: 150,
    },
    {
      field: 'value',
      headerName: 'Valor',
      type: 'number',
      flex: 1,
    },
    user?.type == 2 && {
      field: 'comment',
      headerName: 'Comentário',
      flex: 1,
    },
  ];

  return (
    <div className="container">
      <div className="d-flex">
        <h2 className="my-5">Consultas</h2>
        <div className="align-self-center ml-auto d-block">
          {/* {user?.bank_account && (
            <button type="button" className="btn btn-outline-secondary mr-3">
              Transferir para meu banco
            </button>
          )} */}

          {/* <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={handleShow}
          >
            {user?.bank_account ? 'Editar' : 'Cadastrar'} conta de banco
          </button>
          <ModalCadastrarBanco show={show} handleClose={handleClose} /> */}
        </div>
      </div>

      <div className="border-top">
        {isLoading ? (
          <Loading />
        ) : schedules?.length > 0 ? (
          <div style={{ width: '100%' }}>
            <DataGrid
              rows={schedules}
              columns={columns}
              pageSize={10}
              autoHeight
              sortModel={[
                {
                  field: 'date',
                  sort: 'desc',
                },
              ]}
            />
          </div>
        ) : (
          <h3>Sem consultas passadas ...</h3>
        )}
      </div>
    </div>
  );
};

export const ModalCadastrarBanco = ({ show, handleClose }) => {
  const formRef = useRef(null);
  const { user, updateUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  // eu podia fazer isso certo, porem preguiça

  const conversions = {
    conta_corrente: 'CC',
    conta_poupanca: 'CP',
    conta_corrente_conjunta: 'CCC',
    conta_poupanca_conjunta: 'CPC',
  };

  const options = [
    { value: 'conta_corrente', label: 'Conta Corrente' },
    { value: 'conta_poupanca', label: 'Conta Poupança' },
    { value: 'conta_corrente_conjunta', label: 'Conta Corrente Conjunta' },
    { value: 'conta_poupanca_conjunta', label: 'Conta Poupança Conjunta' },
  ];

  const _conversions = {
    CC: options[0],
    CP: options[1],
    CCC: options[2],
    CPC: options[3],
  };

  const initialData = {
    ...user?.bank_account,
    agencia: user?.bank_account?.agency,
    agencia_dv: user?.bank_account?.agency_dv,
    conta: user?.bank_account?.account_number,
    conta_dv: user?.bank_account?.account_dv,
    type: _conversions[user?.bank_account?.type] || options[0],
  };

  async function onSubmit(data) {
    setIsLoading(true);
    if (!data.agencia_dv) delete data.agencia_dv; // gambiarraa
    const newData = {
      ...data,
      document_number: data.document_number.replace(/\W/g, ''),
      type: data.type.value,
    };

    Yup.setLocale({
      ...yupConfig,
    });

    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        agencia: Yup.string().required(),
        agencia_dv: Yup.string(),
        bank_code: Yup.string().required(),
        conta: Yup.string().max(13).required(),
        conta_dv: Yup.string().required(),
        document_number: Yup.string().min(11).required(),
        legal_name: Yup.string().uppercase().max(30).required(),
        type: Yup.string().required(),
      });
      await schema.validate(newData, {
        abortEarly: false,
      });

      const dataPagarMe = {
        anticipatable_volume_percentage: '0',
        api_key: 'ak_test_Yu6TybYEx3gUT4VZmix0ot7s3Rxw7l',
        automatic_anticipation_enabled: 'false',
        bank_account: newData,
        transfer_day: '5',
        transfer_enabled: 'true',
        transfer_interval: 'weekly',
        postback_url: 'https://api.retouch.life/gw/recipients',
      };

      let recipient_id;

      if (user?.bank_account?.recipient_id) {
        const {
          data: { id },
        } = await Axios.put(
          `https://api.pagar.me/1/recipients/${user?.bank_account?.recipient_id}`,
          dataPagarMe
        );
        recipient_id = id;
      } else {
        const {
          data: { id },
        } = await Axios.post('https://api.pagar.me/1/recipients', dataPagarMe);
        recipient_id = id;
      }

      const {
        agencia,
        agencia_dv,
        bank_code,
        conta,
        conta_dv,
        document_number,
        legal_name,
        type,
      } = newData;

      const dataBankAccount = {
        recipient_id,
        agency: agencia,
        agency_dv: agencia_dv,
        bank_code,
        account_number: conta,
        account_dv: conta_dv,
        document_number,
        legal_name,
        type: conversions[type],
      };

      await api.put(
        `users/psychologists/${user.id}/bankaccount`,
        dataBankAccount
      );

      updateUser();
      handleClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      if (err.response) {
        const {
          data: { errors },
        } = err.response;
        if (Array.isArray(errors)) {
          errors.forEach((error) => {
            validationErrors[error.parameter_name] = 'Inválido.';
          });
          formRef.current.setErrors(validationErrors);
        } else console.error(err);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conta bancária</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            ref={formRef}
            onSubmit={onSubmit}
            initialData={initialData}
            class="needs-validation"
            novalidate
          >
            <div className="row">
              <div className="col">
                <InputMask
                  name="bank_code"
                  label="Código do Banco"
                  mask="999"
                />
              </div>
              <div className="col">
                <InputMask name="agencia" label="Agência" mask="99999" />
              </div>
              <div className="col">
                <InputMask
                  name="agencia_dv"
                  label="Dígito"
                  mask="9"
                  small="(opcional)"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <InputMask
                  name="conta"
                  label="Número da conta"
                  mask="9999999999999"
                />
              </div>
              <div className="col">
                <InputMask name="conta_dv" label="Dígito" mask="99" />
              </div>
            </div>
            <InputMask
              name="document_number"
              label="CPF"
              mask="999.999.999-99"
            />
            <InputMask name="legal_name" label="Nome" />
            <div className="form-group">
              <label>Tipo de conta</label>
              <Select name="type" options={options} />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Fechar
          </Button>
          <Button
            variant="primary"
            onClick={() => formRef.current.submitForm()}
            disabled={isLoading}
          >
            {isLoading ? 'Salvando...' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Recebimentos;
