import React from 'react';
import Collapse from '../components/Collapse';
import { Card } from './Card';

import phone from '../assets/ic-telphone.png';
import mail from '../assets/ic-mail.png';

function Central() {
  const items = [
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus faucibus at vitae elit. Laoreet leo venenatis, sagittis ac imperdiet hac viverra sagittis. In sit netus ante facilisis scelerisque morbi nibh sed. Aliquet volutpat vitae tellus sed at elementum, pellentesque. Imperdiet amet a massa elementum. Feugiat curabitur consequat lorem vitae, aliquet eget massa.',
    },
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus faucibus at vitae elit. Laoreet leo venenatis, sagittis ac imperdiet hac viverra sagittis. In sit netus ante facilisis scelerisque morbi nibh sed. Aliquet volutpat vitae tellus sed at elementum, pellentesque. Imperdiet amet a massa elementum. Feugiat curabitur consequat lorem vitae, aliquet eget massa.',
    },
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus faucibus at vitae elit. Laoreet leo venenatis, sagittis ac imperdiet hac viverra sagittis. In sit netus ante facilisis scelerisque morbi nibh sed. Aliquet volutpat vitae tellus sed at elementum, pellentesque. Imperdiet amet a massa elementum. Feugiat curabitur consequat lorem vitae, aliquet eget massa.',
    },
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus faucibus at vitae elit. Laoreet leo venenatis, sagittis ac imperdiet hac viverra sagittis. In sit netus ante facilisis scelerisque morbi nibh sed. Aliquet volutpat vitae tellus sed at elementum, pellentesque. Imperdiet amet a massa elementum. Feugiat curabitur consequat lorem vitae, aliquet eget massa.',
    },
    {
      title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus faucibus at vitae elit. Laoreet leo venenatis, sagittis ac imperdiet hac viverra sagittis. In sit netus ante facilisis scelerisque morbi nibh sed. Aliquet volutpat vitae tellus sed at elementum, pellentesque. Imperdiet amet a massa elementum. Feugiat curabitur consequat lorem vitae, aliquet eget massa.',
    },
  ];

  return (
    <>
      <div className="container">
        <h2 className="my-3">Central de ajuda</h2>
        <h4 className="w-50 my-4">
          Uma lista de perguntas mais frequentes. Caso não ache a solução, entre
          em contato conosco diretamente.
        </h4>
        <Collapse title="Perguntas" items={items} />

        <h3 className="w-50 mx-5 my-5">Ainda tem perguntas sem respostas?</h3>

        <div className="row">
          <div className="col-xl">
            <Card img={phone} imgStyle={{ height: 48 }} title="(XX) 0000-0000">
              <p className="card-text px-2 mb-3">
                De segunda a sexta das 9h às 13h e das 14h às 18h
                <br />
                exceto feriados
              </p>
            </Card>
          </div>
          <div className="col-xl">
            <Card
              img={mail}
              imgStyle={{ height: 48 }}
              title="atendimento@humantouch.com.br"
            >
              <p className="card-text px-2 mb-3">
                Disponível 24h
                <br />
                Responderemos assim que possível
              </p>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Central;
