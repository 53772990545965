import axios from 'axios';
import Swal from 'sweetalert2';

// TODO: SETUP ENV
const api = axios.create({
  baseURL: 'https://api.retouch.life/api/',
});

api.defaults.headers.common['Content-Type'] = 'application/json';
// api.defaults.headers.common['Access-Token'] = '60a156bb175722a22f2d97e6dd6bb762';

// api.interceptors.request.use(async (config) => {
//   const headers = {
//     // referer: 'https://www.arboimoveis.com.br/',
//     // origin: 'https://www.arboimoveis.com.br/',
//     ...config.headers,
//     // 'referrer-policy': 'strict-origin-when-cross-origin',
//   };
//   return { ...config, headers };
// });
api.interceptors.request.use((config) => {
  if (!config.headers.common.Authorization && localStorage.getItem('token')) {
    const token = localStorage.getItem('token');
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
});

// api.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     const { message, errors } = error.response.data;
//     Swal.fire({
//       title: message,
//       icon: 'error',
//       html: `<pre>${JSON.stringify(errors)}</pre>`,
//     });
//     return Promise.reject(error);
//   }
// );
export default api;
