import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';

export default function Textarea({ name, className, label, ...rest }) {
  const inputRef = useRef(null);

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <textarea
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={`${error ? 'is-invalid' : ''} ${className}`}
        onFocus={(e) => {
          if (e) clearError(e);
        }}
        {...rest}
      />

      {error && <span className="invalid-feedback">{error}</span>}
    </>
  );
}
