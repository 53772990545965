import React, { useEffect, useState } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function updateIsMobile() {
      if (window.innerWidth <= 576) {
        setIsMobile(true);
      } else setIsMobile(false);
    }
    updateIsMobile();

    window.addEventListener('resize', updateIsMobile, { passive: true });
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return [isMobile, setIsMobile];
};

export default useIsMobile;
