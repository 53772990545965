import React from 'react';

import bubble from '../assets/ic-bubble.png';
import recomendacao from '../assets/ic-recomendacao.png';
import agenda from '../assets/ic-agenda.png';

const Card = ({ img, description, title, paragraph, height }) => (
  <div className="row">
    <div className="col-xl">
      <div className="card my-3 my-xl-0">
        <div className="card-body text-center">
          <img src={img} className="my-4" height={height} alt="" />
          <p className="card-hint ">{description}</p>
          <h3 className="card-title px-3">{title}</h3>
          <p className="card-text px-2 mb-3">{paragraph}</p>
        </div>
      </div>
    </div>
  </div>
);

function Section2() {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <h3>Encontre o especialista certo para você</h3>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-xl mx-1">
            <Card
              img="circle.png"
              height="128px"
              description="1 PASSO"
              title="Uma resposta aqui, uma resposta ali. Voilà!"
              paragraph="Responda nosso questionário de perguntas para definir seu perfil ... Lorem ipsum dolor sit amet."
            />
          </div>
          <div className="col-xl mx-1">
            <Card
              img="compass.png"
              height="128px"
              description="2 PASSO"
              title="É amor à primeira, segunda, terceira vista."
              paragraph="Nós recomendamos os especialistas ideais para você... Lorem ipsum dolor sit amet, consectetur adipiscing."
            />
          </div>
          <div className="col-xl mx-1">
            <Card
              img="internet.png"
              height="128px"
              description="3 PASSO"
              title="Agende a consulta quando puder. Sem esforço."
              paragraph="Selecione a data e hora de sua preferrência, efetue o pagamento e aguarde até a sua consulta."
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section2;
