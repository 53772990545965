import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Input from '../components/Input';
import { useAuth } from '../hooks/auth';
import useBackgroundColor from '../utils/useBackgroundColor';
import api from '../utils/api';
import RadioInput from '../components/RadioInput';
import Select from '../components/Select';
import InputMask from '../components/InputMask';
import yupConfig from '../utils/yupConfig';

Yup.addMethod(Yup.string, 'isNewEmail', function (args) {
  return Yup.string()
    .email()
    .test(`isNewEmail`, async function test(value) {
      const { path, createError } = this;
      if (!value) return;

      const {
        data: [resEmail],
      } = await api.post('user/emailcheck', {
        email: value,
      });

      return resEmail != 'false' || createError({ path, message: args });
    });
});

// Yup.addMethod(Yup.string, 'empCode', function (args) {
//   return Yup.string().test(`empCode`, async function test(value) {
//     const { path, createError } = this;
//     if (!value) return;

//     const { data } = await api.get(`users/companies/code/${value}`);
//     value = data.data[0]?.id;
//     console.log(data);

//     return true;
//   });
// });

const SejaPaciente = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const formRef = useRef(null);
  useBackgroundColor();

  const [step, setStep] = useState(1);
  const [form, setForm] = useState({
    status: true,
  });

  const [agesOptions, setAgesOptions] = useState([]);
  const [gendersOptions, setGendersOptions] = useState([]);
  const [specialtiesOptions, setSpecialtiesOptions] = useState([]);
  const [featuresOptions, setFeaturesOptions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const agesPromise = api.get('ages');
      const gendersPromise = api.get('genders');
      const specialtiesPromise = api.get('specialties');
      const featuresPromise = api.get('features');

      const transform = (item) => ({
        id: item.id,
        value: item.id,
        label: item.name,
      });

      const [agesRes, gendersRes, specialtiesRes, featuresRes] =
        await Promise.all([
          agesPromise,
          gendersPromise,
          specialtiesPromise,
          featuresPromise,
        ]);

      const {
        data: { data: agesData },
      } = agesRes;
      const {
        data: { data: gendersData },
      } = gendersRes;
      const {
        data: { data: specialtiesData },
      } = specialtiesRes;
      const {
        data: { data: featuresData },
      } = featuresRes;

      const ages = agesData.map(transform);
      const genders = gendersData.map(transform);
      const specialties = specialtiesData.map(transform);
      const features = featuresData.map(transform);

      setAgesOptions(ages);
      setGendersOptions(genders);
      setSpecialtiesOptions(specialties);
      setFeaturesOptions(features);
    }

    document.getElementsByTagName('body')[0].style.background = 'none';
    fetchData();
  }, []);

  async function handleSubmit(data) {
    Yup.setLocale({
      ...yupConfig,
    });
    let schema;
    formRef.current.setErrors({});
    try {
      switch (step) {
        case 1:
          schema = Yup.object().shape({
            email: Yup.string().isNewEmail('E-mail já cadastrado.').required(),
            password: Yup.string().min(8).required(),
            phone: Yup.string()
              .matches(
                /(\(\d{2}\)\s)(((?=9)\d{5}-\d{4})|((?=\d)\d{5}-\d{3})(?!\d))/,
                'Telefone inválido.'
              )
              .required(),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 3:
          schema = Yup.object().shape({
            name: Yup.string().min(4).required(),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 4:
          schema = Yup.object().shape({
            age: Yup.object().shape({
              id: Yup.string().required('Selecione uma opção.'),
            }),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 5:
          schema = Yup.object().shape({
            gender: Yup.object().shape({
              id: Yup.string().required('Selecione uma opção.'),
            }),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 6:
          schema = Yup.object().shape({
            specialties: Yup.array().min(1),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        case 7:
          schema = Yup.object().shape({
            features: Yup.array().min(1),
          });
          await schema.validate(data, {
            abortEarly: false,
          });
          break;
        default:
          break;
      }
      const newForm = { ...form, ...data };

      try {
        if (step == 1) {
          if (newForm?.company) {
            const { data: resCompany } = await api.get(
              `users/companies/code/${newForm?.company}`
            );
            if (resCompany.data[0]?.id)
              newForm.company = resCompany.data[0]?.id;
            else newForm.company = null;
          } else newForm.company = null;
        }
      } catch (error) {
        console.error('Falha no parse do company');
      }

      setForm(newForm);

      if (step != 7) {
        setStep(step + 1);
      } else {
        setStep(step + 1);
        try {
          await api.post('users/patients', newForm);

          await signIn({
            email: form.email,
            password: form.password,
          });

          history.push('/agendar-consulta');
        } catch (error) {
          console.error(error);
          alert('Algo deu errado, tente novamente');
        }
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        // console.log(validationErrors);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  const handleGoBack = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const props = {
    step,
    formRef,
    nextStep,
  };

  const goBack = step != 1 && (
    <>
      <button
        type="button"
        className="btn btn-outline-primary my-3 mx-5"
        onClick={handleGoBack}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
    </>
  );

  const CurrentStep = () => {
    switch (step) {
      case 1:
        return <Step1 {...props} />;
      case 2:
        return <Step2 {...props} />;
      case 3:
        return <Step3 {...props} />;
      case 4:
        return <Step4 {...props} resOptions={agesOptions} />;
      case 5:
        return <Step5 {...props} resOptions={gendersOptions} />;
      case 6:
        return <Step6 {...props} resOptions={specialtiesOptions} />;
      case 7:
        return <Step7 {...props} resOptions={featuresOptions} />;
      default:
        return <Spinner animation="border" variant="primary" />;
    }
  };

  return (
    <>
      <div className="container">
        {goBack}
        <Form
          ref={formRef}
          className="row justify-content-center"
          onSubmit={handleSubmit}
          initialData={form}
        >
          <CurrentStep />
        </Form>
      </div>

      {step > 2 && step < 8 && (
        <div className="container-fluid bg-white py-5">
          <div className="row justify-content-center">
            <div className="col-5 my-3">
              {[
                'Qual é o seu nome?',
                'Qual é a sua idade?',
                'Como você se identifica?',
                'Quais são os motivos que você busca ajuda?',
                'Quais as características que você prefere?',
              ].map((stringStep, i) => {
                if (i + 2 >= step) {
                  return <h5>{stringStep}</h5>;
                }
                return null;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Step1 = () => (
  <div className="col-12 col-sm-6 my-3">
    <h3>Bem-vindo(a)!</h3>
    <h4>Conecte-se com especialistas criando seu perfil em poucos minutos.</h4>

    <Input
      name="email"
      type="email"
      placeholder="exemplo@email.com.br"
      label="E-mail"
    />
    <Input name="password" type="password" label="Senha" />
    <InputMask
      name="phone"
      placeholder="(00) 99999-9999"
      mask="(99) 99999-9999"
      label="Telefone"
    />
    <Input name="company" label="Código" small="(opcional)" />

    <button type="submit" className="btn btn-lg btn-primary btn-block my-3">
      Aceitar e cadastrar
    </button>

    <a
      href="/login"
      className="text-primary d-flex justify-content-center mb-3"
    >
      Se você ja tem uma conta, clique aqui para entrar
    </a>
    <small className="d-flex flex-column text-center justify-content-center">
      <p>Ao cadastrar seus dados, você concorda com nossos</p>
      <div>
        <span className="text-primary">Termos e Condições</span> e{' '}
        <span className="text-primary">Política de Privacidade</span>
      </div>
      .
    </small>
  </div>
);

const Step2 = ({ nextStep }) => (
  <div className="col-10 my-3">
    <h2>
      Muito bem!
      <br /> Para criar seu perfil, vou precisar saber algumas informações sobre
      você.
    </h2>
    <button className="btn btn-primary my-3" type="button" onClick={nextStep}>
      Sim, continuar
    </button>
  </div>
);

const Step3 = () => {
  const isValid = true;

  return (
    <div className="col-8 my-3">
      <CustomInput
        name="name"
        label="Qual é o seu nome?"
        placeholder="Digite aqui sua resposta"
      />
      <button
        className={
          isValid ? 'btn btn-primary my-3' : 'btn btn-outline-dark my-3'
        }
        disabled={!isValid}
        type="submit"
      >
        Próxima pergunta
      </button>
    </div>
  );
};

const Step4 = ({ resOptions }) => {
  const [options, setOptions] = useState(resOptions || []);

  return (
    <div className="col-8 my-3">
      <h5>Qual é a sua idade?</h5>
      <div className="d-flex flex-column">
        <RadioInput name="age.id" options={options} />
      </div>
      <button className="btn btn-primary my-3" type="submit">
        Proximo
      </button>
    </div>
  );
};

const Step5 = ({ resOptions }) => {
  const [options, setOptions] = useState(resOptions || []);

  return (
    <div className="col-8 my-3">
      <h5>Como você se identifica?</h5>
      <div className="d-flex flex-column">
        <RadioInput name="gender.id" options={options} />
      </div>
      <button className="btn btn-primary my-3" type="submit">
        Proximo
      </button>
    </div>
  );
};

const Step6 = ({ resOptions }) => {
  const isValid = true;

  const [options, setOptions] = useState(resOptions || []);

  return (
    <div className="col-8 my-3">
      <h5>Quais são os motivos que você busca ajuda?</h5>
      <Select name="specialties" options={options} isMulti />
      <button
        className={
          isValid ? 'btn btn-primary my-3' : 'btn btn-outline-dark my-3'
        }
        disabled={!isValid}
        type="submit"
      >
        Próxima pergunta
      </button>
    </div>
  );
};

const Step7 = ({ resOptions }) => {
  const [options, setOptions] = useState(resOptions || []);

  return (
    <div className="col-8 my-3">
      <h5>Quais as características que você prefere?</h5>
      <Select name="features" options={options} isMulti />

      <button className="btn btn-primary my-3" type="submit">
        Buscar especialistas
      </button>
    </div>
  );
};

const CustomInput = ({ label, ...props }) => (
  <div className="form-group">
    {label && <h5>{label}</h5>}
    <Input
      className="px-0 form-control-lg border-bottom border-dark border-top-0 border-left-0 border-right-0 rounded-0"
      style={{ fontSize: '2.25rem', background: '#F1F2F6' }}
      {...props}
    />
  </div>
);

export default SejaPaciente;
