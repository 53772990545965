import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

const CheckboxInput = ({ name, options, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  function legacyCode(acc, cur) {
    acc[cur] = true;
    return acc;
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) =>
        // console.log(refs);
        refs
          .filter((ref) => ref.checked)
          .map((ref) => ref.value)
          .reduce(legacyCode, {}),
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <div className="d-flex justify-content-around">
      {options.map((option, index) => (
        <label htmlFor={option.id} key={option.id}>
          <input
            // defaultChecked={defaultValue?.find((dv) => dv === option.id)}
            // defaultChecked={Object.entries(defaultValue).find(
            //   (item) => item[0] == option.id && item[1] == 1
            // )}
            defaultChecked={defaultValue ? defaultValue[option.id] : false}
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            value={option.value}
            type="checkbox"
            id={option.id}
            style={{ display: 'block', margin: '0 auto' }}
            {...rest}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};
export default CheckboxInput;
