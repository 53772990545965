import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Nav, Navbar } from 'react-bootstrap';
// import logo from '../assets/logotipo.svg';
import { useAuth } from '../hooks/auth';

function Header() {
  const { user, signOut } = useAuth();
  const history = useHistory();
  // console.log(user);

  const handleLogOut = () => {
    history.push('/');
    signOut();
  };

  const logOutButton = (
    <Nav.Link className="align-self-center" onClick={handleLogOut}>
      <FontAwesomeIcon
        icon={faSignOutAlt}
        className="align-self-center text-black-6 c-pointer"
      />
    </Nav.Link>
  );

  const admin = (
    <>
      <Nav.Link href="/admin" className="mx-3 align-self-center">
        Admin
      </Nav.Link>
      <Nav.Link
        href="/admin#/users/psychologists/all"
        className="mx-3 align-self-center"
      >
        Psicólogos
      </Nav.Link>
      <Nav.Link
        href="/admin#/users/patients/all"
        className="mx-3 align-self-center"
      >
        Pacientes
      </Nav.Link>
      <Nav.Link
        href="/admin#/users/companies"
        className="mx-3 align-self-center"
      >
        Empresas
      </Nav.Link>
      <Nav.Link
        href="/admin#/users/schedules/all"
        className="mx-3 align-self-center"
      >
        Agendamentos
      </Nav.Link>
      {logOutButton}
    </>
  );

  const noUser = (
    <>
      <Nav.Link href="/seja-parceiro" className="mx-3 align-self-center">
        Seja um parceiro
      </Nav.Link>
      <Nav.Link href="/central" className="mx-3 align-self-center">
        Central de ajuda
      </Nav.Link>

      <Link to="/login" className="mx-3 align-self-center">
        <button className="btn btn-outline-secondary" type="submit">
          Acessar conta
        </button>
      </Link>
    </>
  );

  const defaultLinks = (
    <>
      <Nav.Link href="/perfil" className="align-self-center mx-3">
        Consultas Agendadas
      </Nav.Link>
      <Nav.Link href="/agenda" className="align-self-center mx-3">
        Agenda
      </Nav.Link>
      <Nav.Link href="/recebimentos" className="align-self-center mx-3">
        Consultas Efetuadas
      </Nav.Link>
      <Nav.Link href="/meu-perfil" className="align-self-center mx-3">
        Meu perfil
      </Nav.Link>
    </>
  );

  const Psicologo = (
    <>
      {defaultLinks}
      {logOutButton}
    </>
  );

  const Usuario = (
    <>
      {defaultLinks}
      <Nav.Link href="/agendar-consulta" className="align-self-center">
        <button className="btn btn-primary" type="submit">
          Agendar consulta
        </button>
      </Nav.Link>
      {logOutButton}
    </>
  );

  const Links = useMemo(
    () =>
      !user?.type
        ? noUser
        : user?.type == 2
        ? Psicologo
        : user?.type == 1
        ? admin
        : Usuario,
    [user?.type]
  );

  return (
    <header className="container-xl border-bottom py-3">
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <img src="/logotipo.svg" alt="" loading="lazy" height="70px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">{Links}</Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
